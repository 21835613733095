import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GuiMessageInterface } from '@archimed-frontend/archimed-common';

@Injectable({ providedIn: 'root' })
export class GuiMessageService implements GuiMessageInterface {
  constructor(private messageService: MessageService) {}

  public showSuccess(summary: string, detail?: string, key?: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
      key: key,
      life: 3000,
      closable: true,
    });
  }

  public showError(summary: string, detail?: string, key?: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
      key: key,
      life: 6000,
      closable: true,
    });
  }
}
