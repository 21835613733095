import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from '@archimed-frontend/archimed-common';
import { TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { finalize } from 'rxjs/operators';
import { AppStateService } from '../../shared/app-state.service';
import { GuiMessageService } from '../../shared/message/gui-message.service';

@Component({
  templateUrl: 'forgotpassword.component.html',
  styles: [
    `
      h2 {
        margin-bottom: 40px;
      }
      .form-left-box {
        margin-right: 42px;
        float: left;
      }
    `,
  ],
})
export class ForgotpasswordComponent implements OnInit {
  state$ = this.appState.select();
  model: any = {};
  showSuccess: boolean | undefined;
  loading = false;

  constructor(
    private appState: AppStateService,
    private userService: UserService,
    private matomoTracker: MatomoTracker,
    private titleService: Title,
    private translateService: TranslateService,
    private guiMessageService: GuiMessageService
  ) {
    // this.appState.hold(this.routeParams$);
  }

  ngOnInit() {
    console.log(this.titleService.getTitle());
    this.matomoTracker.setDocumentTitle(this.titleService.getTitle());
    this.matomoTracker.setCustomUrl('/forgotpassword');
    this.matomoTracker.trackPageView();
  }

  requestPassword() {
    this.loading = true;
    this.userService
      .sendAccessLinkToUser(this.model.username)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          this.showSuccess = true;
        },
        () => {
          this.guiMessageService.showError('An error occurred');
        }
      );
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }
}
