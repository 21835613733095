import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Portal } from '@archimed-frontend/archimed-common';

@Component({
  selector: 'supportbox',
  templateUrl: 'supportbox.component.html',
  styleUrls: ['supportbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupportboxComponent {
  @Input()
  currentPortal: Portal;
}
