import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppSettings } from '@archimed-frontend/archimed-common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class NewsletterService extends BaseService {
  private url = `${this.endpointUrl}/v1/newsletter`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  syncAllUsers(): Observable<null> {
    return this.http.post<null>(`${this.url}/sync-all-users`, null);
  }
}
