import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppSettings, APP_CONFIG } from '../../model/appsettings';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class CMSService extends BaseService {
  private portalApiUrl = this.endpointUrl + '/v1/cms';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getCMSListing(folderName: string, mode?: string): Observable<TreeNode[]> {
    let params: HttpParams = new HttpParams();

    if (mode != null) {
      params = params.set('mode', `${mode}`);
    }

    return this.http
      .get<TreeNode[]>(
        `${this.portalApiUrl}/list/${folderName}?${params.toString()}`
      )
      .pipe(catchError(this.handleError<TreeNode[]>('getCMSListing')));
  }

  createFolder(parentFolder: string, folderName: string): Observable<unknown> {
    let params: HttpParams = new HttpParams();

    if (folderName != null) {
      params = params.set('foldername', `${folderName}`);
    }

    return this.http
      .get<unknown>(
        `${this.portalApiUrl}/new/${parentFolder}?${params.toString()}`
      )
      .pipe(catchError(this.handleError<unknown>('createFolder')));
  }
}
