import { LazyLoadEvent } from 'primeng/api';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PagedRequest } from './pagedRequest';
import { GuiMessageInterface } from '../message/gui-message.interface';
import { TableLazyLoadEvent } from 'primeng/table';

export class RestUtils {
  static lazyLoadEventToPagedRequest(
    event: LazyLoadEvent | TableLazyLoadEvent
  ): PagedRequest {
    const pagedRequest = new PagedRequest();
    pagedRequest.firstRow = event.first;
    pagedRequest.rows = event.rows;
    pagedRequest.filters = event.filters;
    return pagedRequest;
  }

  static pagedRequestToHttpParams(
    pagedRequest: PagedRequest,
    httpParams?: HttpParams
  ): HttpParams {
    httpParams = httpParams ?? new HttpParams();
    if (pagedRequest?.rows != null) {
      const rows = pagedRequest.rows;
      httpParams = httpParams.set('size', rows);
      if (pagedRequest?.firstRow != null) {
        const firstRow = pagedRequest.firstRow;
        const page = Math.trunc(firstRow / rows);
        const remainder = firstRow % rows;
        if (remainder !== 0) {
          console.error(
            `PagedRequest parameters do not fit (rows=${rows}, firstRow=${firstRow}`
          );
        }
        httpParams = httpParams.set('page', page);
      }
    }
    return httpParams;
  }

  static getPaginationHttpParams(
    event: LazyLoadEvent | TableLazyLoadEvent,
    httpParams?: HttpParams
  ): HttpParams {
    return this.pagedRequestToHttpParams(
      this.lazyLoadEventToPagedRequest(event),
      httpParams
    );
  }

  static showHttpAwareError(
    summary: string,
    guiMessageService: GuiMessageInterface,
    key?: string
  ) {
    return (error: Error) => {
      this.showHttpError(error, summary, guiMessageService, key);
      throw error;
    };
  }

  static showHttpError(
    error: Error,
    summary: string,
    guiMessageService: GuiMessageInterface,
    key?: string
  ) {
    let detail: string;
    if (error instanceof HttpErrorResponse && error.error.message != null) {
      detail = error.error.message;
    } else {
      detail = 'An unknown error occurred.';
    }
    guiMessageService.showError(summary, detail, key);
  }
}
