import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackButtonListenerService {
  backButtonClicked: BehaviorSubject<string> = new BehaviorSubject('');

  dontIntercept = false;
  lastWasBackButton = false;

  backButtonWasClicked(url: string) {
    if (this.lastWasBackButton) {
      this.backButtonClicked.next(url);
      this.backButtonClicked.next(''); // this is necsesary because the behavioursubject retains the last value,
      // and by (my ;) api, "" should be ignored. (So when a new component registers, the value it receives is "")
      this.lastWasBackButton = false;
    }
  }

  lastRouteWasBackButton(wasBack: boolean) {
    this.lastWasBackButton = wasBack;
  }
}
