import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@archimed-frontend/archimed-common';
import { TranslateService } from '@ngx-translate/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { Subscription } from 'rxjs';
import { AppStateService } from '../../shared/app-state.service';

@Component({
  templateUrl: 'logout.component.html',
})
export class LogoutComponent implements OnInit {
  state$ = this.appState.select();

  routerSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private appState: AppStateService,
    private authenticationService: AuthenticationService,
    private matomoTracker: MatomoTracker,
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    // console.log('translateService', this.translateService);
    // this.routerSubscription = this.route.paramMap.subscribe((paramMap: ParamMap) => {
    //     console.log('paramMap', paramMap.get('lang'));
    //     if (paramMap.has('lang')) {
    //         this.translateService.use(paramMap.get('lang'))
    //         .subscribe(() => this.languageLoaded = true);
    //     } else {
    //         this.translateService.use('en')
    //         .subscribe(() => this.languageLoaded = true);
    //     }
    // });
    this.appState.set({ currentUser: undefined });
    this.authenticationService.refresh.next(-1);
    // this.loadingService.stop();
    this.matomoTracker.setDocumentTitle(this.titleService.getTitle());
    this.matomoTracker.setCustomUrl('/logout');
    this.matomoTracker.trackPageView();
  }

  currentLang(): string {
    return this.translateService.currentLang;
  }
}
