<p-toast position="top-center">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <h4 translate>{{ message.summary }}</h4>
      <p
        style="white-space: pre-wrap"
        [innerHTML]="message.detail | translate"
      ></p>
    </div>
  </ng-template>
</p-toast>
<router-outlet></router-outlet>
