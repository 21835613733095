<div class="header">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col">
        <a href="/" class="logo">
          <img
            src="/assets/img/Archimdelife-Logo-CMYK.svg"
            alt="{{ currentPortal.name }}"
          />
        </a>
      </div>
    </div>
  </div>
</div>
