import {
  HttpClient,
  HttpHeaders,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  interval,
  throwError,
} from 'rxjs';
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../model/appsettings';
import { Portal } from '../model/portal';
import { PortalUser } from '../model/portaluser';
import { BaseService } from '../service/base.service';
import { UserService } from '../service/rest/user.service';
import { Token } from './token';
import { marker as transKey } from '@colsen1991/ngx-translate-extract-marker';

// We use this custom URLEncoder because the HttpParams one from Angular does not encode '+', instead just
// replaces it with 'space'. Therefore we hab problems with passowrd including '+' signs.
// AP-658
// https://stackoverflow.com/questions/51942703/character-converting-into-space-in-httpparams-angular-6

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {
  oauthUrl = this.endpointUrl + '/oauth';
  private headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  );
  loggedInPortalUser: PortalUser;

  refresh: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  refreshSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    super(config);
  }

  initAutoLogout(validTime: number) {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

    this.refreshSubscription = this.refresh
      .pipe(
        switchMap((expiryTime: number) => interval((expiryTime - 2) * 1000)), // we subtract 2 seconds as a buffer so the final logout call is more likely to succeed at the server
        takeWhile((val) => val > 0)
      )
      .subscribe(
        (val) => console.log('do logout', val),
        (error) => console.log('error', error),
        () => {
          // console.log('last value', this.refresh.value);
          if (this.refresh.value !== -1) {
            this.messageService.add({
              severity: 'warn',
              summary: transKey('$global.alert.your-session-has-expired'),
            });
          }
          this.logout(false);
          this.navigateToLogin();
          console.log('logging out');
        }
      );
    this.refresh.next(validTime);
  }

  login(username: string, password: string, portal: Portal): Observable<void> {
    const body = new HttpParams({ encoder: new CustomURLEncoder() })
      .set('scope', 'read')
      .set('username', username)
      .set('password', password)
      .set('client_id', portal.name)
      .set('grant_type', 'password');

    return this.http
      .post<Token>(this.oauthUrl + '/token', body, { headers: this.headers })
      .pipe(
        map((token: Token) => {
          // login successful if there's a jwt token in the response
          if (token && token.access_token) {
            this.initAutoLogout(token.refresh_expires_in);
            // store user details and token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem('token', JSON.stringify(token));
          }
        })
      );
  }

  refreshToken(): Observable<string> {
    const token: Token = JSON.parse(sessionStorage.getItem('token'));
    const portal: Portal = JSON.parse(sessionStorage.getItem('portal'));

    let body = new HttpParams()
      .set('scope', 'read')
      .set('grant_type', 'refresh_token');

    if (token) {
      body = body.set('refresh_token', token.refresh_token);
    } else {
      return throwError(
        'Cannot perform refresh request without refresh token!'
      );
    }

    if (portal) {
      body = body.set('client_id', portal.name);
    }

    return this.http
      .post<Token>(this.oauthUrl + '/token', body, { headers: this.headers })
      .pipe(
        map((token: Token) => {
          // retrieving refresh token successful if there's a jwt token in the response
          if (token && token.access_token) {
            // store user details and token in local storage to keep user logged in between page refreshes
            // console.log('Refresh Token recieved and it expires in ' + token.refresh_expires_in + ' seconds.');
            this.refresh.next(token.refresh_expires_in);
            sessionStorage.setItem('token', JSON.stringify(token));
            return token.access_token;
          }
          return undefined;
        }),
        catchError((error) => {
          console.log('An error occured getting the refresh token', error);
          return throwError(error);
        })
      );
  }

  hastoken(): boolean {
    return sessionStorage.getItem('token') != null;
  }

  getAuthorizationHeader(): Token {
    return JSON.parse(sessionStorage.getItem('token'));
  }

  // should not be called from outside. Trigger a logout by calling authService.refresh.next(-1)
  logout(clientOnly: boolean) {
    // remove user from local storage to log user out
    if (!clientOnly) {
      this.http.get(this.endpointUrl + '/v1/users/logout').subscribe(
        (resp) => {
          console.log('logged out', resp);
          this.refresh.next(-1);
          sessionStorage.removeItem('token');
        },
        (error) => {
          console.log('logout: error', error);
          this.refresh.next(-1);
          sessionStorage.removeItem('token');
        },
        () => {
          console.log('complete logout');
        }
      );
    } else {
      console.log('Logout Client only');
      this.refresh.next(-1);
      sessionStorage.removeItem('token');
    }
  }

  navigateToLogin() {
    this.router.navigate([
      '/login/' +
        (this.translateService.currentLang
          ? this.translateService.currentLang
          : ''),
    ]);
  }
}
