import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ArchimedCommonModule,
  DefaultMissingTranslationHandler,
} from '@archimed-frontend/archimed-common';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { EqualToValidator } from './equal-to.directive';
import { PasswordValidatorDirective } from './password.directive';
import { SelectSpecializationComponent } from './select-specialization.component';
import { SelectSpecializationReactiveComponent } from './selectspecialization.component';
import { SupportboxComponent } from './supportbox.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler,
      },
    }),
    ArchimedCommonModule,
    InputTextModule,
    DropdownModule,
    OverlayPanelModule,
    InputSwitchModule,
  ],
  declarations: [
    SupportboxComponent,
    SelectSpecializationComponent,
    SelectSpecializationReactiveComponent,
    PasswordValidatorDirective,
    EqualToValidator,
  ],
  exports: [
    SupportboxComponent,
    SelectSpecializationComponent,
    SelectSpecializationReactiveComponent,
    PasswordValidatorDirective,
    EqualToValidator,
  ],
})
export class ArchimedSharedModule {}
