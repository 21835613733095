import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PortalService } from '../../service/rest/portal.service';
import { ResourcePage } from '../../model/resourcePage';

export interface TemplateInfoState {
  refEntityId: number;
  portalId: number;
  language: string;
  templateInfo: ResourcePage | null;
  resourceType: string;
}

@Component({
  selector: 'template-info',
  styles: [
    `
      .template-info {
        margin-left: 50px;
        background: #f5f5f5;
        width: 270px;
        padding: 9px;
        font-size: 16px;
      }

      .template-info p {
        font-size: 16px;
      }
    `,
  ],
  templateUrl: 'template-info.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [RxState],
})
export class TemplateInfoComponent {
  state$ = this.state.select();

  @Input()
  set portalId(portalId: number) {
    this.state.set({ portalId });
  }

  @Input()
  set refEntityId(refEntityId: number) {
    this.state.set({ refEntityId });
  }

  @Input()
  set language(language: string) {
    this.state.set({ language });
  }

  @Input()
  set resourceType(resourceType: string) {
    this.state.set({ resourceType });
  }

  private loadResource$ = combineLatest([
    this.state.select('portalId'),
    this.state.select('refEntityId'),
    this.state.select('language'),
    this.state.select('resourceType'),
  ]).pipe(
    switchMap(([portalId, refEntityId, language, resourceType]) => {
      return this.portalService.getResourcePageForPortal(
        resourceType,
        refEntityId,
        portalId,
        language
      );
    })
  );

  constructor(
    private state: RxState<TemplateInfoState>,
    private portalService: PortalService
  ) {
    this.state.connect('templateInfo', this.loadResource$);
  }
}
