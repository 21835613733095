import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    const authToken = JSON.parse(sessionStorage.getItem('token'));
    if (authToken != null) {
      return req.clone({
        headers: req.headers.set(
          'Authorization',
          authToken.token_type + ' ' + authToken.access_token
        ),
      });
    } else {
      return req;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addAuthorizationHeader(req)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(req, next, error);
          }
        }
        return throwError(error);
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any) {
    // console.log('401', error);
    // console.log('401-error', error.error);
    if (
      error.error &&
      error.error.error_description != null &&
      error.error.error_description.startsWith('Invalid refresh token')
    ) {
      return this.logoutUser(true);
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      // let authService = this.injector.get(AuthenticationService);

      return this.authService.refreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addAuthorizationHeader(req));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser(true);
        }),
        catchError((error) => {
          console.log(error);
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser(true);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addAuthorizationHeader(req));
        })
      );
    }
  }

  handle400Error(error: any) {
    console.log('error 400', error);
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser(true);
    }
    return throwError(error);
  }

  logoutUser(clientOnly: boolean) {
    // let authService = this.injector.get(AuthenticationService);
    this.authService.logout(clientOnly);
    return throwError('Could not get new authentication token');
  }
}
