import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../base.service';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Observable } from 'rxjs';
import { Country } from '../../model/country';

@Injectable({ providedIn: 'root' })
export class PublicRestService extends BaseService {
  private url = `${this.endpointUrl}/public`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  /**
   * Returns all countries which are not restricted.
   * To get all countries use the method in CountryRestService.
   *
   * @param langKey Return countries with names in this language.
   */
  getCountries(langKey: string): Observable<Country[]> {
    // Why is Greece's country code EL in the European Union but GR everywhere else?
    // https://www.quora.com/Why-is-Greeces-country-code-EL-in-the-European-Union-but-GR-everywhere-else
    if (langKey === 'gr') {
      langKey = 'el';
    }
    const params = new HttpParams().set('lang-key', langKey);
    return this.http.get<Country[]>(`${this.url}/countries`, { params });
  }
}
