import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { BackButtonListenerService } from '../service/backlistener.service';

@Injectable({ providedIn: 'root' })
export class BackGuard implements CanDeactivate<any> {
  constructor(
    private router: Router,
    private backButtonListener: BackButtonListenerService
  ) {}

  canDeactivate(component: any) {
    if (
      !this.backButtonListener.lastWasBackButton ||
      this.backButtonListener.dontIntercept
    ) {
      this.backButtonListener.lastWasBackButton = false;
      return true;
    }
    history.pushState(null, null, location.href);
    this.backButtonListener.backButtonWasClicked(location.href);
    return false;
  }
}
