import { Pipe, PipeTransform } from '@angular/core';

/**
 *  Date Transformer
 */
@Pipe({ name: 'prepareDate' })
export class PrepareDateForServicePipe implements PipeTransform {
  /**
   * Hier wird von ein mit einer ZeitZone versehenes Date()-Objekt
   * in einen UTC-Timestamp OHNE ZeitZone umgewandelt als <Datum> - 00:00:00
   * <br/>
   * 19.01.1938 11:11:11  CET -> 19.01.1938 00:00 UTC als Timestamp
   * @param date zu beschneidendes Datum
   */
  transform(date: Date): string {
    const transformedDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).toString();

    // console.log( "return from transform: " + transformedDate )
    return transformedDate;
  }

  /**
   * Return Today as String 01/12/1978
   */
  getTodayAsString(): string {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1; // January is 0!
    let ddString = dd.toString();
    let mmString = mm.toString();

    if (dd < 10) {
      ddString = '0' + ddString;
    }

    if (mm < 10) {
      mmString = '0' + mmString;
    }

    return ddString + '/' + mmString + '/' + today.getFullYear();
  }

  /**
   * Validate if Date is in given time range
   * @param date
   * @param minDate
   * @param maxDate
   */
  validateDate(date: any, minDate: Date, maxDate: Date): boolean {
    const dayInMilliSeconds = 1000 * 60 * 60 * 24;
    const isDate: boolean = date instanceof Date;
    if (!isDate) {
      return false;
    }

    return (
      date.getTime() / dayInMilliSeconds >=
        minDate.getTime() / dayInMilliSeconds - 1 &&
      date.getTime() / dayInMilliSeconds <=
        maxDate.getTime() / dayInMilliSeconds
    );
  }

  /**
   * Schneidet Zeit von einem Datum ab und schickt es als
   * UTC timestamp z.b. "1534896000000" zurück
   * @param date
   */
  removeTimeFromDateReturnUTC(date: any): string {
    let newdate;
    // Wenn String dann Date()-Objekt aus String erzeugen.
    if (!(date instanceof Date)) {
      newdate = new Date(date);
    } else {
      newdate = date;
    }
    return new Date(
      Date.UTC(newdate.getFullYear(), newdate.getMonth(), newdate.getDate())
    )
      .getTime()
      .toString();
  }

  /**
   * Wandelt ein UTC TimeStamp Datumsstring in ein lokales Datum ohne Zeitzone um.
   * @param dateUTCString
   */
  convertUTCDateToLocalDate(dateUTCString: string): Date {
    // this.patient.birthDate -> liefert Integer in UTC
    // Wenn man daraus ein Date() - Objekt macht,
    // wird die im Betriebssystem eingestellte Zeitzone dazu gerechnet.

    // Wenn das Date()-Objekt dann an den DateTime-Picker übergeben wird,
    // stellt dieser es "falsch" dar.

    // -1008288000000 wäre der 19.01.1938
    // Hat man im Betriebsystem zum Beispiel die UTC-1 = GMT-0200 (Cape Verde Standard Time)
    // so werden vom Datum 2 Stunden abgezogen und deshalb wird diese zu 18.01.1938 22:00 Uhr
    // und im Datetime Picker wird der 18.01.1938 angezeigt.

    // Dass heißt wir müssen den UTC Timestamp in ein Date()-Objekt ohne TimeZone-Offset verpacken.
    // date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    let dateWithoutTimeZone = new Date(dateUTCString);

    dateWithoutTimeZone = new Date(
      dateWithoutTimeZone.getUTCFullYear(),
      dateWithoutTimeZone.getUTCMonth(),
      dateWithoutTimeZone.getUTCDate()
    );

    // console.log("UTC: " + dateUTCString + " dateWithoutTimeZone: " + dateWithoutTimeZone + " Date()-Object: " + new Date(dateUTCString).toString());

    return dateWithoutTimeZone;
  }
}
