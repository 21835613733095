<div class="desc">
  <p>
    <!--strong>Brauchen Sie Hilfe?</strong-->
    <strong translate="">supportbox.need-help-or-any-additional-info</strong>
  </p>
  <p>
    <a href="mailto:{{ currentPortal?.emailContact }}"
      ><span class="pi pi-envelope"></span> {{ currentPortal.emailContact }}</a
    >
  </p>
  <p *ngIf="currentPortal.phone">
    <a href="{{ currentPortal?.phone | formatPhone }}"
      ><span class="pi pi-phone"></span> {{ currentPortal.phone }}</a
    >
  </p>
  <div class="row-bottom">
    <!--p>Unser Team ist immer für Sie da!</p-->
    <p translate="">supportbox.please-do-not-hesitate-to-contac</p>
  </div>
</div>
