<div class="container" *ngIf="state$ | async as state">
  <h1 translate *ngIf="!wasMetaRegistration">resetpassword.reset-password</h1>
  <h1 *ngIf="wasMetaRegistration" translate>
    resetpassword.please-set-yourself-a-password
  </h1>
  <div class="row">
    <div class="col-md-4">
      <form
        class="form-left-box"
        name="form"
        (ngSubmit)="resetPassword(pwdf)"
        #pwdf="ngForm"
        novalidate=""
      >
        <input type="text" style="display: none" />
        <input type="password" style="display: none" />
        <div
          class="form-group"
          [ngClass]="{ 'has-error': pwdf.submitted && !password.valid }"
        >
          <label for="password" translate>resetpassword.new-password</label>
          <input
            type="password"
            class="form-control"
            validatePassword=""
            minlength="8"
            name="password"
            [(ngModel)]="model.password"
            #password="ngModel"
            required=""
          />
          <div
            *ngIf="pwdf.submitted && !password.valid"
            class="help-block"
            translate
          >
            resetpassword.your-password-must-meet-the-foll
            <ul>
              <li translate>resetpassword.use-at-least-eight-characters</li>
              <li translate>resetpassword.use-at-least-one-lower-case-lett</li>
              <li translate>resetpassword.use-at-least-one-uppercase-lette</li>
              <li translate>resetpassword.use-at-least-one-number-or-one-s</li>
            </ul>
          </div>
        </div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': pwdf.submitted && !confirmedpassword.valid
          }"
        >
          <label for="confirmedpassword" translate
            >resetpassword.confirm-password</label
          >
          <input
            type="password"
            class="form-control"
            name="confirmedpassword"
            [(ngModel)]="model.confirmedpassword"
            #confirmedpassword="ngModel"
            [equalTo]="password"
          />
          <div
            *ngIf="pwdf.submitted && !confirmedpassword.valid"
            class="help-block"
            translate
          >
            resetpassword.confirmed-password-must-match-wi
          </div>
        </div>

        <div class="form-group">
          <button
            type="submit"
            *ngIf="!wasMetaRegistration"
            [disabled]="loading.active"
            class="btn"
            translate
          >
            resetpassword.reset-password
          </button>
          <button
            type="submit"
            *ngIf="wasMetaRegistration"
            [disabled]="loading.active"
            class="btn"
          >
            Set Password
          </button>
          <loading></loading>
          <!--a [routerLink]="['/dashboard']" class="btn-simple">Cancel</a-->
        </div>
      </form>
    </div>
    <div class="col-md-5">
      <supportbox [currentPortal]="state.portal"></supportbox>
    </div>
  </div>
  <span
    *ngIf="wasMetaRegistration && passwordChangeDone"
    class="col-md-5 row"
    translate
    >resetpassword.your-passwort-was-set-you-will-a</span
  >
</div>
