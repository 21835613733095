import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Portal, UserService } from '@archimed-frontend/archimed-common';
import { RxState } from '@rx-angular/state';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../../shared/app-state.service';

export interface OnetimeState {
  portal: Portal;
  token: string | null;
  loading: boolean;
}

@Component({
  templateUrl: 'onetimeaccess.component.html',
  styles: [
    `
      h2 {
        margin-bottom: 40px;
      }
      .form-left-box {
        margin-right: 42px;
        float: left;
      }
    `,
  ],
  providers: [RxState],
})
export class OnetimeaccessComponent {
  state$ = this.state.select();

  urlToken$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  checkOnetimeToken$ = this.state.select('token').pipe(
    switchMap((token) =>
      this.userService.onetimeaccess(token).pipe(
        tap((lang) => {
          if (lang != 'false') {
            this.router.navigate(['/resetpassword/' + token + '/' + lang]);
          }
        }),
        finalize(() => this.state.set({ loading: false }))
      )
    )
  );

  constructor(
    private appState: AppStateService,
    private state: RxState<OnetimeState>,
    // public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.state.connect('portal', this.appState.select('portal'));
    this.state.connect(
      this.urlToken$.pipe(map((token) => ({ token, loading: true })))
    );
    this.state.hold(this.checkOnetimeToken$);
  }
}
