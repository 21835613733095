import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

/** Custom implementation of the Angular RouteReuseStrategy. All method implmentations are the
 * defaults. Only in shouldReuseRoute we disable Component cacheing for the new analysis page.
 * We always want to newly create the new analysis component if we change the pool token in the
 * Url.
 */
export class PhysicianRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    if (future.routeConfig?.path == 'pool/:poolid') {
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}
