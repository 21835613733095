import { Role } from './role';

export class Portal {
  id: number;
  city: string;
  company: string;
  country: string;
  emailSupport: string;
  emailContact: string;
  name: string;
  phone: string;
  street: string;
  title: string;
  url: string;
  zip: string;
  port: number;
  isPortal: number;
  siteId: number;
  imprint: string;
  tos: string;
  privacy: string;
  tagline: string;
  isoCode: string;
  authorities: Role[];
  lastLoad: number;
}
