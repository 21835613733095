import { Inject, Injectable } from '@angular/core';
import { AppSettings, APP_CONFIG } from '../../model/appsettings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { VipManagementResult } from '../../model/vipManagementResult';

@Injectable()
export class VipService extends BaseService {
  private url = this.endpointUrl + '/v1/vip';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  addUsers(
    poolId: number,
    userList: string,
    sendEmail: boolean
  ): Observable<VipManagementResult> {
    const body = {
      poolId,
      userList,
      sendEmail,
    };
    return this.http.post<VipManagementResult>(
      `${this.url}/add-users-to-pool`,
      body
    );
  }

  removeUsers(
    poolId: number,
    userList: string
  ): Observable<VipManagementResult> {
    const body = {
      poolId,
      userList,
    };
    return this.http.post<VipManagementResult>(
      `${this.url}/remove-users-from-pool`,
      body
    );
  }
}
