<ng-container *ngIf="state$ | async as state">
  <header-loggedout [currentPortal]="state.portal"></header-loggedout>
  <div class="container">
    <div [hidden]="!showSuccess">
      <h2 translate="">forgotpassword.access-link-sent</h2>
      <br />
      <br />
      <div class="col-md-5 row">
        <br />
        <p translate="">forgotpassword.you-will-receive-an-e-mail-to-ac</p>
        <p translate="">forgotpassword.please-also-check-your-spam-fold</p>
        <a
          [routerLink]="['/login/' + (currentLang() ? currentLang() : '')]"
          class="btn btn-login"
          translate=""
          >login.log-in</a
        >
      </div>
      <div class="col-md-5 row">
        <supportbox
          [currentPortal]="state.portal"
          class="pull-right"
        ></supportbox>
      </div>
    </div>

    <div [hidden]="showSuccess">
      <h2 translate="">forgotpassword.request-new-password</h2>
      <form
        class="form-left-box"
        name="form"
        (ngSubmit)="f.form.valid && requestPassword()"
        #f="ngForm"
        novalidate
      >
        <div
          class="form-group"
          [ngClass]="{ 'has-error': f.submitted && !username.valid }"
        >
          <label for="username" translate="">forgotpassword.e-mail</label>
          <input
            maxlength="50"
            type="email"
            class="form-control"
            name="username"
            [(ngModel)]="model.username"
            #username="ngModel"
            email=""
            required
          />
          <div
            *ngIf="f.submitted && !username.valid"
            class="help-block"
            translate=""
          >
            forgotpassword.please-provide-a-valid-e-mail
          </div>
        </div>

        <div class="form-group">
          <button
            pButton
            pRipple
            [disabled]="loading"
            class="btn"
            [label]="'forgotpassword.send-password-recovery-e-mail' | translate"
          ></button>
          <a
            [routerLink]="['/login/' + (currentLang() ? currentLang() : '')]"
            class="btn-simple"
            translate=""
            >forgotpassword.cancel</a
          >
        </div>
      </form>
      <supportbox [currentPortal]="state.portal"></supportbox>
    </div>
  </div>
</ng-container>
