import { LocationStrategy } from '@angular/common';
import { Component } from '@angular/core';
import {
  BackButtonListenerService,
  PortalService,
} from '@archimed-frontend/archimed-common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'archimed-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'archimed-physician';

  constructor(
    private portalService: PortalService,
    private translateService: TranslateService,
    private locationStrategy: LocationStrategy,
    private backListener: BackButtonListenerService
  ) {
    console.log(environment.version);
    this.translateService.setDefaultLang('en');
    this.portalService.loadPortal();
    this.locationStrategy.onPopState(() => {
      // console.log('popState');
      this.backListener.lastRouteWasBackButton(true);
      return false;
    });
  }
}
