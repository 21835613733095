import { Component, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PortalService } from '../../service/rest/portal.service';

export interface ExternalPoolState {
  refEntityId: number;
  portalId: number;
  language: string;
  resourcePage: any;
}

@Component({
  selector: 'external-pool',
  templateUrl: 'external-pool.component.html',
  providers: [RxState],
})
export class ExternalPoolComponent {
  state$ = this.state.select();

  @Input()
  set portalId(portalId: number) {
    this.state.set({ portalId });
  }

  @Input()
  set refEntityId(refEntityId: number) {
    this.state.set({ refEntityId });
  }

  @Input()
  set language(language: string) {
    this.state.set({ language });
  }

  loadResource$ = combineLatest([
    this.state.select('portalId'),
    this.state.select('refEntityId'),
    this.state.select('language'),
  ]).pipe(
    switchMap(([portalId, refEntityId, language]) => {
      // Note: error handling
      return this.portalService.getResourcePageForPortal(
        'externalpool',
        refEntityId,
        portalId,
        language
      );
    })
  );

  constructor(
    private portalService: PortalService,
    private state: RxState<ExternalPoolState>
  ) {
    this.state.connect('resourcePage', this.loadResource$);
  }
}
