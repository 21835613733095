import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ArchimedCommonModule,
  DefaultMissingTranslationHandler,
} from '@archimed-frontend/archimed-common';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HeaderLoggedoutComponent } from './header-loggedout.component';
import { ForgotpasswordComponent } from './login/forgotpassword.component';
import { ButtonModule } from 'primeng/button';
import { ArchimedSharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { ActivationComponent } from './register/activation.component';
import { ResetpasswordComponent } from './register/resetpassword.component';
import { OnetimeaccessComponent } from './register/onetimeaccess.component';
import { MessagesModule } from 'primeng/messages';
import { GuiMessageService } from '../shared/message/gui-message.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler,
      },
    }),
    RouterModule,
    ArchimedCommonModule,
    ArchimedSharedModule,
    InputTextModule,
    DropdownModule,
    OverlayPanelModule,
    ButtonModule,
    InputSwitchModule,
    MessagesModule,
  ],
  declarations: [
    HeaderLoggedoutComponent,
    ForgotpasswordComponent,
    LoginComponent,
    LogoutComponent,
    ActivationComponent,
    ResetpasswordComponent,
    OnetimeaccessComponent,
  ],
  exports: [
    HeaderLoggedoutComponent,
    ForgotpasswordComponent,
    LoginComponent,
    LogoutComponent,
    ActivationComponent,
    ResetpasswordComponent,
    OnetimeaccessComponent,
  ],
  providers: [GuiMessageService],
})
export class LoggedoutModule {}
