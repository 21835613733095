import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Portal } from '@archimed-frontend/archimed-common';

@Component({
  selector: 'header-loggedout',
  templateUrl: './header-loggedout.component.html',
  styleUrls: ['./header-loggedout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderLoggedoutComponent {
  @Input()
  currentPortal: Portal;
}
