import { Observable } from 'rxjs';
import { PortalUser } from '../model/portaluser';

export abstract class AuthState {
  abstract getCurrentUser(): PortalUser;
  abstract selectCurrentUser(): Observable<PortalUser>;
  abstract fetchCurrentUser(): void;
}

// export const AUTH_STATE = new InjectionToken<AuthState>('AuthState');
