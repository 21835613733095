<ng-container *ngIf="state$ | async as state">
  <header-loggedout [currentPortal]="state.portal"></header-loggedout>
  <div class="container">
    <h2 translate="">logout.logged-out-successfully</h2>
    <div class="row">
      <div class="col-md-5">
        <br />
        <p translate="">logout.you-have-been-logged-out-success</p>
        <br />
        <div class="form-group">
          <a
            [routerLink]="['/login/' + state.currentLanguage]"
            class="btn"
            translate=""
            >logout.log-in</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
