import { Country } from './country';
import { Physician } from './physician';
import { Role } from './role';

export class PortalUser {
  id: number;
  username: string;
  password: string;
  newpassword: string;
  firstName: string;
  lastName: string;
  gender: string;
  title: string;
  country: Country;
  language: string;
  physicians: Physician[];
  relatedCount: number;
  roles: Role[];
  authorities: Role[];
  deactivationDate: Date;
  accountNonLocked: boolean;
  accountNonExpired: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
}
