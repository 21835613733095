import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Country } from '../../model/country';
import { PortalUser } from '../../model/portaluser';
import { Role } from '../../model/role';
import { BaseService } from '../base.service';

@Injectable()
export class LabWorkerService extends BaseService {
  private url = this.endpointUrl + '/v1/lab-worker';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getPools(): Observable<number[]> {
    return this.http
      .get<number[]>(`${this.url}/pool-ids`)
      .pipe(catchError(this.handleError<number[]>('pools')));
  }

  getLabWorkers(): Observable<PortalUser[]> {
    return this.http
      .get<PortalUser[]>(`${this.url}/list`)
      .pipe(catchError(this.handleError<PortalUser[]>('getLabWorkers')));
  }

  getLabWorker(id: number): Observable<PortalUser> {
    return this.http
      .get<PortalUser>(`${this.url}/byId/${id}`)
      .pipe(catchError(this.handleError<PortalUser>('getPhysician')));
  }

  createLabWorker(user: PortalUser): Observable<PortalUser> {
    return this.http
      .post<PortalUser>(`${this.url}/create`, user)
      .pipe(catchError(this.handleError<PortalUser>('createLabWorker')));
  }

  updateLabWorker(user: PortalUser): Observable<PortalUser> {
    return this.http
      .put<PortalUser>(`${this.url}/${user.id}`, user)
      .pipe(catchError(this.handleError<PortalUser>('updateLabWorker')));
  }

  getRoles(): Observable<Role[]> {
    return this.http
      .get<Role[]>(`${this.url}/roles`)
      .pipe(catchError(this.handleError<Role[]>('getRoles')));
  }

  updateLabWorkerRoles(
    labworkerId: number,
    roles: Role[]
  ): Observable<PortalUser> {
    return this.http
      .put<PortalUser>(`${this.url}/roles/${labworkerId}`, roles)
      .pipe(catchError(this.handleError<PortalUser>('updateLabWorkerRoles')));
  }

  getLabWorkerCountries(labworkerId: number): Observable<Country[]> {
    return this.http
      .get<Country[]>(`${this.url}/${labworkerId}/countries`)
      .pipe(catchError(this.handleError<Country[]>('getLabWorkerCountries')));
  }

  updateLabWorkerCountries(
    labworkerId: number,
    countries: Country[]
  ): Observable<Country[]> {
    return this.http
      .put<Country[]>(`${this.url}/countries/${labworkerId}`, countries)
      .pipe(
        catchError(this.handleError<Country[]>('updateLabWorkerCountries'))
      );
  }
}
