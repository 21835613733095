import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppSettings, APP_CONFIG } from '../../model/appsettings';
import { SupportedLanguage } from '../../model/supportedLanguage';
import { BaseService } from '../base.service';
import { Portal } from '../../model/portal';

@Injectable({
  providedIn: 'root',
})
// implements Resolve<SupportedLanguage[]>
export class TranslationLoadService extends BaseService {
  private publicApiUrl = this.endpointUrl + '/public';

  private supportedLanguages: SupportedLanguage[];

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    super(config);
  }

  /**
   * Hole über die API alle verfügbaren Sprachen für das Portal.
   * Wenn eine Sprache dabei ist, die der im Browser eingestellten Sprache enspricht, liefere diese zurück.
   * Wenn nicht, dann liefere Englisch als Fallback.
   *
   * Improvement
   * Die optimale Lösung wäre, falls die erste Browsersprache nicht greift, alle anderen Sprachen,
   * die der Browser im Accept-Language-Header mitschickt, durchzuiterieren und zu schauen,
   * ob eine Sprache dabei ist, die auch vom Portal unterstützt wird.    *
   *
   * z.B. Accept-Language et,es;q=0.8,en-US;q=0.6,de;q=0.4,en;q=0.2
   * wird im NGX-Translate abgefragt mit:
   * this.translateService.getLangs(): Returns an array of currently available langs
   */

  // resolve(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<SupportedLanguage[]> {
  //   return this.loadSupportedLanguagesAsync().pipe(
  //     mergeMap((languageList: SupportedLanguage[]) => {
  //       this.supportedLanguages = languageList;
  //       if (route.paramMap.get('lang')) {
  //         // if language in root, use this language
  //         return this.setLanguage(route.paramMap.get('lang'));
  //       } else {
  //         if (this.getCurrentSessionLanguage() != null) {
  //           // else, if language found in sessionStorage, use this. (happens if user logged in language or in dev mode)
  //           console.log(
  //             'found current session language',
  //             this.getCurrentSessionLanguage()
  //           );
  //           return this.setLanguage(this.getCurrentSessionLanguage());
  //         } else {
  //           console.log('using browser lang');
  //           return this.setLanguage(this.translateService.getBrowserLang()); // if nothing else is set, use the browser language
  //         }
  //       }
  //     })
  //   );
  // }

  getJson(language: string): Observable<any> {
    return this.http.get(this.publicApiUrl + '/i18/' + language);
  }

  loadSupportedLanguagesAsync(
    portal?: Portal
  ): Observable<SupportedLanguage[]> {
    if (this.supportedLanguages) {
      return of(this.supportedLanguages);
    } else {
      let params = new HttpParams();
      params = portal != null ? params.set('portalid', portal.id) : params;
      return this.http
        .get<SupportedLanguage[]>(this.publicApiUrl + '/supportedLanguages', {
          params,
        })
        .pipe(map((langs) => langs.filter((l) => !l.disabled)));
    }
  }

  getSupportedLanguages(): SupportedLanguage[] {
    return this.supportedLanguages;
  }

  getSupportedLanguageFor(langKey: string): SupportedLanguage {
    return this.supportedLanguages.find((lang) => lang.langKey === langKey);
  }

  setLanguage(language: string): Observable<string> {
    // if (language !== this.translateService.currentLang) { // check not really necessary; ngx-translate performs this check as well
    //     if (language !== 'en') { // english is always supported. this check avoids errors in Backoffice App
    //         if (this.supportedLanguages.findIndex((lang) => lang.langKey === language) === -1) { // language not supported
    //             language = 'en'; // default to en
    //         }
    //     }
    return this.translateService.use(language).pipe(
      tap((ret) => {
        // console.log('set language to ', language, ret);
        sessionStorage.setItem('lang', language);
      })
      // map((ret) => language)
    );
    // } else {
    // return of(language);
    // }
  }

  getCurrentSessionLanguage(): string {
    return sessionStorage.getItem('lang');
  }

  getCurrentSessionLanguageAsSupportedLanguage(): SupportedLanguage {
    return this.getSupportedLanguageFor(this.getCurrentSessionLanguage());
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  getCurrentLanguageAsSupportedLanguge(): SupportedLanguage {
    return this.getSupportedLanguageFor(this.translateService.currentLang);
  }

  getBrowserLanguage(): string {
    return this.translateService.getBrowserLang();
  }
}
