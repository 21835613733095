import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Physician } from '../../model/physician';
import { Portal } from '../../model/portal';
import { PagedResult } from '../../util/pagedresult';
import { BaseService } from '../base.service';
import { TableLazyLoadEvent } from 'primeng/table';

@Injectable({
  providedIn: 'root',
})
export class DelegateRestService extends BaseService {
  private url = this.endpointUrl + '/v1/delegate';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getDelegatesPaged(
    physician: Physician,
    portal: Portal,
    lazyLoadEvent: TableLazyLoadEvent
  ) {
    const params = this.getPaginationHttpParams(lazyLoadEvent).set(
      'physician-id',
      physician.id
    );
    const headers = this.getHeadersWithPortal(portal);
    return this.http.post<PagedResult<Physician>>(
      `${this.url}/paged`,
      lazyLoadEvent.filters,
      { params, headers }
    );
  }

  setDownloadReportAllowed(delegate: Physician, allowed: boolean) {
    return this.http.put<void>(
      `${this.url}/${delegate.id}/report-download`,
      allowed
    );
  }

  setEnabled(delegate: Physician, enabled: boolean) {
    return this.http.put<void>(`${this.url}/${delegate.id}/enabled`, enabled);
  }

  update(delegateId: number, delegate: Physician) {
    return this.http.put<void>(`${this.url}/${delegateId}`, delegate);
  }
}
