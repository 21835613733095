import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AnalysisPool } from '../../model/analysispool';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Physician } from '../../model/physician';
import { Portal } from '../../model/portal';
import { PagedResult } from '../../util/pagedresult';
import { BaseService } from '../base.service';
import { PagedRequest } from '../../util/pagedRequest';
import { RestUtils } from '../../util/restUtils';

@Injectable({
  providedIn: 'root',
})
export class PhysicianService extends BaseService {
  cachedPhysicians: any = {};

  private portalPhysicianUrl = this.endpointUrl + '/v1/physicians';
  private portalPublicUserUrl = this.endpointUrl + '/public';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  //////////////////////////// PUBLIC

  registerPhysician(physician: Physician): Observable<Physician> {
    return this.http.post<Physician>(
      this.portalPublicUserUrl + '/register',
      physician
    );
  }

  /////////////////////////// AUTHENTICATED USER

  registerPhysicianLoggedIn(
    physician: Physician,
    portal?: Portal
  ): Observable<Physician> {
    const headers =
      portal != null ? this.getHeadersWithPortal(portal) : new HttpHeaders();
    return this.http.post<Physician>(
      this.portalPhysicianUrl + '/register',
      physician,
      { headers }
    );
  }

  updatePhysician(physician: Physician): Observable<Physician> {
    return this.http.put<Physician>(
      this.portalPhysicianUrl + '/' + physician.id,
      physician
    );
  }

  updatePoolsForPhysician(
    physicianId: number,
    portalId: number,
    newPools: AnalysisPool[]
  ) {
    return this.http.put<Physician>(
      `${this.portalPhysicianUrl}/pools/${physicianId}?portalid=${portalId}`,
      newPools
    );
  }

  getPhysician(id: number): Observable<Physician> {
    return this.http
      .get<Physician>(this.portalPhysicianUrl + '/byId/' + id)
      .pipe(
        tap((physician: Physician) => {
          this.cachedPhysicians[id] = physician;
        }),
        catchError(this.handleError<Physician>('getPhysician'))
      );
  }

  getPhysiciansByPortal(
    portal: Portal,
    size?: number,
    page?: number,
    filterOptions?: any
  ): Observable<PagedResult<Physician>> {
    let params: HttpParams = new HttpParams();
    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }

    return this.http
      .post<PagedResult<Physician>>(
        `${this.portalPhysicianUrl}/list/lab/${portal.id}?${params.toString()}`,
        filterOptions
      )
      .pipe(
        catchError(
          this.handleError<PagedResult<Physician>>(
            'getPhysiciansByPortal',
            new PagedResult()
          )
        )
      );
  }

  getList(
    portal: Portal,
    pagedRequest: PagedRequest
  ): Observable<PagedResult<Physician>> {
    let params: HttpParams = new HttpParams();
    params = params.set('portal-id', portal.id);
    params = RestUtils.pagedRequestToHttpParams(pagedRequest, params);
    return this.http.post<PagedResult<Physician>>(
      `${this.portalPhysicianUrl}/list`,
      pagedRequest.filters,
      { params }
    );
  }

  getRelatedPhysicians(
    size?: number,
    page?: number,
    filterOptions?: any
  ): Observable<PagedResult<Physician>> {
    let params: HttpParams = new HttpParams();

    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }

    return this.http
      .post<PagedResult<Physician>>(
        `${this.portalPhysicianUrl}/related?${params.toString()}`,
        filterOptions
      )
      .pipe(
        catchError(
          this.handleError<PagedResult<Physician>>(
            'getRelatedPhysicians',
            new PagedResult()
          )
        )
      );
  }

  sendComplianceMail(): Observable<void> {
    return this.http
      .get<void>(`${this.portalPhysicianUrl}/compliance`)
      .pipe(catchError(this.handleError<void>('sendComplianceMail')));
  }
}
