<div id="main" class="login-page" *ngIf="state$ | async as state">
  <div class="container-fluid container-height">
    <div class="login-page">
      <div class="container-height">
        <div class="row row-height">
          <div
            class="col-sm-12 col-lg-4 col-height portal-{{ state.portal.id }}"
          >
            <h1>{{ state.portal.title }}</h1>
            <p class="tagline">{{ state.portal.tagline }}</p>
          </div>
          <div class="col-sm-12 col-lg-8 col-right">
            <p-messages
            *ngIf="platform.TRIDENT" severity="warn">
            <ng-template pTemplate>
              <div innerHtml="{{ 'login.browser-message' | translate}}"></div>
            </ng-template></p-messages>
            <div class="logo">{{ state.portal.name }}</div>
            <div class="holder-form">
              <form
                name="form"
                (ngSubmit)="f.form.valid && login()"
                #f="ngForm"
                novalidate
              >
                <div
                  class="form-group"
                  [ngClass]="{ 'has-error': f.submitted && !username.valid }"
                >
                  <input
                    maxlength="50"
                    placeholder="{{ 'login.e-mail' | translate }}"
                    type="email"
                    class="form-control"
                    name="username"
                    [(ngModel)]="model.username"
                    #username="ngModel"
                    required=""
                    translate=""
                  />
                  <div
                    *ngIf="f.submitted && !username.valid"
                    class="help-block"
                    translate=""
                  >
                    login.username-is-required
                  </div>
                </div>

                <div
                  class="form-group"
                  [ngClass]="{ 'has-error': f.submitted && !password.valid }"
                >
                  <input
                    placeholder="{{ 'login.password' | translate }}"
                    type="password"
                    class="form-control"
                    name="password"
                    [(ngModel)]="model.password"
                    #password="ngModel"
                    required=""
                    translate=""
                  />
                  <div
                    *ngIf="f.submitted && !password.valid"
                    class="help-block"
                    translate=""
                  >
                    login.password-is-required
                  </div>
                </div>
                <div class="form-group">
                  <button
                    pButton
                    pRipple
                    id="log-in"
                    [loading]="loading.active"
                    class="btn btn-login"
                    [label]="'login.log-in' | translate"
                  ></button>
                  <span class="forgot-password"
                    ><a routerLink="/forgotpassword" translate=
                      >login.forgot-password</a
                    ></span
                  >
                </div>
                <!-- <loading></loading> -->
                <p>
                  <span translate="">login.don-t-have-an-account</span>
                  <a class="register-now" routerLink="/register" translate
                    >login.sign-up-now</a
                  >
                  <span class="help-registration">
                    <a
                      href="{{ 'help.link' | translate }}"
                      target="_blank"
                      translate=""
                      >login.download-registration-guide</a
                    >
                  </span>
                </p>
              </form>
              <supportbox [currentPortal]="state.portal"></supportbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer" class="portal-{{ state.portal.id }}">
        <div class="row">
          <div class="col-sm-4">
            <address>
              <p>{{ state.portal.company }}</p>
              <p>{{ state.portal.street }}</p>
              <p>
                {{ state.portal.zip }} {{ state.portal.city | translate }},
                {{ state.portal.country | translate }}
              </p>
            </address>
          </div>
          <div class="col-sm-4">
            <!--p>Kontakt und Hilfe</p-->
            <p translate="">footer-portal.contact-our-support-team</p>
            <ul>
              <li>
                <a href="mailto:{{ state.portal.emailContact }}">{{
                  state.portal.emailContact
                }}</a>
              </li>
              <li *ngIf="state.portal.phone">
                <a href="{{ state.portal.phone | formatPhone }}">{{
                  state.portal.phone
                }}</a>
              </li>
              <li>
                <i class="pi pi-globe"></i>&nbsp;
                <a href="https://www.archimedlife.com">www.archimedlife.com</a>
              </li>
            </ul>
          </div>
          <div class="col-sm-4">
            <ul>
              <li>
                <a
                  target="_blank"
                  href="{{ state.portal.imprint }}"
                  translate=""
                  >footer-portal.imprint</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  href="{{ state.portal.privacy }}"
                  translate=""
                  >footer-portal.data-privacy</a
                >
              </li>
              <li>
                <a target="_blank" href="{{ state.portal.tos }}" translate=""
                  >footer-portal.terms-conditions</a
                >
              </li>
            </ul>
          </div>
        </div>
    </div>
  </div>
</div>
