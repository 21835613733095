import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PagedResult } from '../../util/pagedresult';
import { PagedRequest } from '../../util/pagedRequest';
import { RestUtils } from '../../util/restUtils';

@Injectable({
  providedIn: 'root',
})
export class WhatsappService extends BaseService {
  private portalWhatsappUrl = this.endpointUrl + '/v1/whatsapp';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  registerNumber(whatsapp: string): Observable<any> {
    return this.http
      .put<any>(`${this.portalWhatsappUrl}/register`, whatsapp)
      .pipe(tap((resp) => console.log(resp)));
  }

  activateNumber(watoken: string): Observable<any> {
    return this.http
      .put<any>(`${this.portalWhatsappUrl}/activate`, watoken)
      .pipe(tap((resp) => console.log(resp)));
  }

  resetActivation(): Observable<any> {
    return this.http
      .get<any>(`${this.portalWhatsappUrl}/reset`)
      .pipe(tap((resp) => console.log(resp)));
  }

  listIncomingMessages(
    pagedRequest: PagedRequest
  ): Observable<PagedResult<any>> {
    let params: HttpParams = new HttpParams();
    params = RestUtils.pagedRequestToHttpParams(pagedRequest, params);
    return this.http
      .get<any>(`${this.portalWhatsappUrl}/incoming`, { params: params })
      .pipe(tap((resp) => console.log(resp)));
  }

  testHook(): Observable<any> {
    return this.http
      .post<any>('/api/public/whatsapp', { test: 'test' })
      .pipe(tap((resp) => console.log(resp)));
  }
}
