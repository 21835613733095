import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Analysis } from '../../model/analysis';
import { AnalysisReport } from '../../model/analysisreport';
import { AnalysisTemplate } from '../../model/analysistemplate';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Physician } from '../../model/physician';
import { PagedResult } from '../../util/pagedresult';
import { SortParams } from '../../util/sortparams';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ReviewService extends BaseService {
  private reviewUserURL = this.endpointUrl + '/v1/analysis';
  private portalDownload = this.endpointUrl + '/v1/analysis/download/';

  private newReviews = new Subject<number>();
  private newReviewsCurrent = 0;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    super(config);
  }

  numberOfNewAnalysisWaitingToBeDownloaded(): Observable<number> {
    return this.http
      .get<number>(this.reviewUserURL + '/waiting-for-download')
      .pipe(
        // Note: stephan: Counter komplett auf getNewReviews() umstellen
        tap((count: number) => {
          this.newReviews.next(count);
          this.newReviewsCurrent = count;
        })
      );
  }

  physiciansByAnalysisDbsId(dbsId: string): Observable<Physician[]> {
    let params: HttpParams = new HttpParams();

    if (dbsId) {
      params = params.set('dbsId', `${dbsId}`);
    }
    return this.http
      .get<Physician[]>(`${this.reviewUserURL}/cro?${params.toString()}`)
      .pipe(catchError(this.handleError<Physician[]>('waitingfordownload')));
  }

  getNewReviews(): Observable<any> {
    return this.newReviews.asObservable();
  }

  getAnalysis(sort: SortParams, physicianId: number): Observable<Analysis[]> {
    let url: string = this.reviewUserURL + '/physician/' + physicianId;
    if (sort) {
      url += '/' + sort.column + '/' + sort.asc; // Note: sortparams schön machen
    }
    return this.http
      .get<Analysis[]>(url)
      .pipe(catchError(this.handleError<Analysis[]>('getAnalysis', [])));
  }

  getAnalysisCompact(physicianId: number): Observable<any[]> {
    const url: string =
      this.reviewUserURL + '/physician/compact/' + physicianId;
    return this.http
      .get<any[]>(url)
      .pipe(catchError(this.handleError<any[]>('getAnalysisCompact', [])));
  }

  findByDbsid(
    dbsId: string,
    size?: number,
    page?: number
  ): Observable<PagedResult<Analysis>> {
    let params: HttpParams = new HttpParams();

    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }
    if (dbsId) {
      params = params.set('dbsId', `${dbsId}`);
    }
    // console.log('service, physicianid', physicianId);
    // let url: string = this.reviewUserURL + '/find/';
    // console.log('url', url);
    return this.http
      .get<PagedResult<Analysis>>(
        `${this.reviewUserURL}/find?${params.toString()}`
      )
      .pipe(catchError(this.handleError<PagedResult<Analysis>>('findByDbsId')));
  }

  byPhysicianFiltered(
    physicianId: number,
    size?: number,
    page?: number,
    sort?: string,
    filterOptions?: any
  ): Observable<PagedResult<Analysis>> {
    let params: HttpParams = new HttpParams();
    params = size != null ? params.set('size', size) : params;
    params = page != null ? params.set('page', page) : params;
    params = sort != null ? params.set('sort', sort) : params;
    return this.http.post<PagedResult<Analysis>>(
      `${this.reviewUserURL}/physician/${physicianId}/list`,
      filterOptions,
      { params }
    );
  }

  downloadUrl(analysisReport: AnalysisReport) {
    return this.portalDownload + analysisReport.fileNameHash;
  }

  /**
   * Downloading a report
   * @param analysisReport the report to download
   */
  downloadFile(
    analysisReport: AnalysisReport,
    downloaded: boolean
  ): Observable<Blob> {
    return this.http
      .get(this.downloadUrl(analysisReport), { responseType: 'blob' })
      .pipe(
        map((res: any) => {
          // https://wiki.selfhtml.org/wiki/MIME-Type/%C3%9Cbersicht
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        tap(
          (blob: Blob) => {
            // https://stackoverflow.com/questions/39375076/how-to-display-pdf-blob-on-ios-sent-from-my-angularjs-app
            FileSaver.saveAs(blob, analysisReport.originalFileName);
            if (!downloaded) {
              this.newReviewsCurrent = this.newReviewsCurrent - 1;
              this.newReviews.next(this.newReviewsCurrent);
            }
          },
          (error) => {
            // Note: move error handling to calling component.
            this.messageService.add({
              severity: 'error',
              summary: 'An error occurred downloading a file',
              detail: error?.message,
            });
          }
        ),
        catchError(this.handleError<Blob>('downloadFile', null))
      );
  }

  downloadAll(analysis: Analysis): Observable<Blob> {
    return this.http
      .get(`${this.portalDownload}analysis/${analysis.id}`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        tap(
          (blob: Blob) => {
            FileSaver.saveAs(blob, `${analysis.dbsId}.zip`);
          },
          (error) => {
            // Note: move error handling to calling component.
            this.messageService.add({
              severity: 'error',
              summary: 'An error occurred downloading a file',
              detail: error?.message,
            });
          }
        ),
        catchError(this.handleError<Blob>('downloadAll', null))
      );
  }

  getTemplates(physicianId: number): Observable<AnalysisTemplate[]> {
    return this.http
      .get<AnalysisTemplate[]>(`${this.reviewUserURL}/templates/${physicianId}`)
      .pipe(
        catchError(this.handleError<AnalysisTemplate[]>('getTemplates', []))
      );
  }
}
