import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppSettings } from '../../../index';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';

@Injectable()
export class DlxService extends BaseService {
  private url = `${this.endpointUrl}/v1/dlx`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  updateAnalysesWithDLXImportStatus(): Observable<any> {
    return this.http.get(this.url + '/update-analyses-with-dlx-import-status');
  }

  importApplicablePdfs(): Observable<any> {
    return this.http.get(this.url + '/import-applicable-pdfs');
  }
}
