import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LoadingService,
  UserService,
} from '@archimed-frontend/archimed-common';

import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '../../shared/app-state.service';
import { marker as transKey } from '@colsen1991/ngx-translate-extract-marker';
import { GuiMessageService } from '../../shared/message/gui-message.service';

@Component({
  selector: 'reset-password-component',
  templateUrl: 'resetpassword.component.html',
  styles: [
    `
      h1 {
        margin-bottom: 40px;
      }
      .form-left-box {
        margin-right: 42px;
        float: left;
      }
    `,
  ],
})
export class ResetpasswordComponent implements OnInit {
  state$ = this.appState.select();
  @Input()
  token: string | undefined;

  model: any = {};
  profileLoaded = false;

  @Input()
  forwardToLogin = true;

  @Input()
  wasMetaRegistration = false;

  @Output()
  registrationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();

  passwordChangeDone = false;

  constructor(
    private appState: AppStateService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    public loading: LoadingService,
    private translateService: TranslateService,
    private guiMessageService: GuiMessageService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
  }

  resetPassword(form: any): void {
    if (!form.form.valid) {
      return;
    }
    this.loading.start();
    this.userService.resetPassword(this.model, this.token).subscribe(
      (data) => {
        form.resetForm();
        if (data) {
          this.loading.stop();
          const successMessage = this.wasMetaRegistration
            ? transKey('resetpassword.your-passwort-was-set-you-will-a')
            : transKey('resetpassword.alert.your-password-has-been-updated');
          this.guiMessageService.showSuccess(successMessage);
          this.registrationComplete.emit(true);
          if (this.forwardToLogin) {
            this.router.navigate(['/login/' + data]);
          }
          this.passwordChangeDone = true;
          this.translateService.use(data);
        } else {
          this.guiMessageService.showError(
            transKey('resetpassword.alert.your-password-has-not-been-changed')
          );
          this.registrationComplete.emit(false);
        }
      },
      (error) => {
        this.guiMessageService.showError(
          error.message ? error.message : 'An error occurred'
        );
        this.loading.stop();
      }
    );
  }
}
