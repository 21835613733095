import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Patient } from '../../model/patient';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PagedResult } from '../../util/pagedresult';
import { Physician } from "../../model/physician";

@Injectable()
export class PatientService extends BaseService {
  private portalPatientUrl = this.endpointUrl + '/v1/patients';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  updatePatient(patient: Patient): Observable<Patient> {
    return this.http
      .put<Patient>(this.portalPatientUrl, patient)
      .pipe(catchError(this.handleError<Patient>('updatePatient')));
  }

  createPatient(patient: Patient): Observable<Patient> {
    return this.http
      .post<Patient>(this.portalPatientUrl, patient)
      .pipe(catchError(this.handleError<Patient>('createPatient')));
  }

  getPatients(
    physicianId: number,
    size?: number,
    page?: number,
    filterOptions?: unknown
  ): Observable<PagedResult<Patient>> {
    let params: HttpParams = new HttpParams();

    if (size) {
      params = params.set('size', `${size}`);
    }
    if (page) {
      params = params.set('page', `${page}`);
    }

    return this.http
      .post<PagedResult<Patient>>(
        `${this.portalPatientUrl}/fuzzy/${physicianId}?${params.toString()}`,
        filterOptions
      )
      .pipe(
        catchError(
          this.handleError<PagedResult<Patient>>(
            'getPatients',
            new PagedResult()
          )
        )
      );
  }

  getDuplicates(
    physician: Physician,
    firstName: string,
    lastName: string,
    birthDate: Date
  ): Observable<Patient[]> {
    const body = {
      firstName, lastName, birthDate, physician
    }
    return this.http.post<Patient[]>(`${this.portalPatientUrl}/duplicate`, body)
  }
}
