import { Inject, Injectable } from '@angular/core';
import {
  AnalysisPool,
  APP_CONFIG,
  AppSettings,
  Country,
  KitType,
  KitTypeLanguage,
  KitTypePool,
  PagedResult,
  PortalUser,
} from '../../../index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { LazyLoadEvent } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class KitTypeService extends BaseService {
  private url = `${this.endpointUrl}/v1/kit-type`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getKitTypes(
    pool: AnalysisPool,
    physician?: PortalUser,
    countryCode?: string
  ): Observable<KitType[]> {
    let params = new HttpParams().set('pool-id', pool.id);
    params =
      physician != null ? params.set('physician-id', physician.id) : params;
    params = countryCode != null ? params.set('country', countryCode) : params;
    return this.http.get<KitType[]>(`${this.url}`, { params });
  }

  getKitTypesPaged(
    lazyLoadEvent: LazyLoadEvent
  ): Observable<PagedResult<KitType>> {
    const params = this.getPaginationHttpParams(lazyLoadEvent);
    return this.http.post<PagedResult<KitType>>(
      `${this.url}/paged`,
      lazyLoadEvent.filters,
      { params }
    );
  }

  saveKitType(kitType: KitType): Observable<number> {
    return this.http.put<number>(`${this.url}/`, kitType);
  }

  removePoolFromKit(kitTypePool: KitTypePool): Observable<void> {
    return this.http.delete<void>(
      `${this.url}/${kitTypePool.kitTypeId}/pool/${kitTypePool.poolId}/country/${kitTypePool.countryId}`
    );
  }

  getKitTypePools(kitType: KitType): Observable<KitTypePool[]> {
    return this.http.get<KitTypePool[]>(`${this.url}/${kitType.id}/pool`);
  }

  addPoolsToKitType(
    kitType: KitType,
    pool: AnalysisPool,
    countries: Country[]
  ): Observable<null> {
    return this.http.post<null>(`${this.url}/${kitType.id}/pool`, {
      pool,
      countries,
    });
  }

  removeLanguageFromKit(
    kitType: KitType,
    kitTypeLanguage: KitTypeLanguage
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.url}/${kitType.id}/language/${kitTypeLanguage.languageId}`
    );
  }

  getKitTypeLanguages(kitType: KitType): Observable<KitTypeLanguage[]> {
    return this.http.get<KitTypeLanguage[]>(
      `${this.url}/${kitType.id}/language`
    );
  }

  addLanguageToKitType(
    kitType: KitType,
    language: KitTypeLanguage
  ): Observable<null> {
    return this.http.post<null>(`${this.url}/${kitType.id}/language`, language);
  }

  getAssignedPools(): Observable<AnalysisPool[]> {
    return this.http.get<AnalysisPool[]>(`${this.url}/assigned-pools/all`);
  }
}
