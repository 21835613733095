import { Observable, of, throwError } from 'rxjs';
import { AppSettings } from '../model/appsettings';
import { LazyLoadEvent } from 'primeng/api';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Portal } from '../model/portal';
import { TableLazyLoadEvent } from 'primeng/table';

export class BaseService {
  protected endpointUrl: string;

  constructor(config: AppSettings) {
    this.endpointUrl = config.API_ENDPOINT;
  }

  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log(`${operation} failed: ${error.message}`, error);
      if (result !== undefined) {
        return of(result as T);
      } else {
        return throwError(error);
      }
    };
  }

  protected getPaginationHttpParams(
    lazyLoadEvent: LazyLoadEvent | TableLazyLoadEvent,
    httpParams?: HttpParams
  ): HttpParams {
    httpParams = httpParams ?? new HttpParams();

    if (lazyLoadEvent.rows == null) {
      return httpParams;
    }
    const rows = lazyLoadEvent.rows;
    httpParams = httpParams.set('size', rows);

    if (lazyLoadEvent.first == null) {
      return httpParams;
    }
    const firstRow = lazyLoadEvent.first;
    const page = Math.trunc(firstRow / rows);
    const remainder = firstRow % rows;
    if (remainder !== 0) {
      console.error(
        `LazyLoadEvent parameters do not fit together (rows=${rows}, firstRow=${firstRow}`
      );
    }
    return httpParams.set('page', page);
  }

  protected getHeadersWithPortalId(portalId: number, headers?: HttpHeaders) {
    return headers != null
      ? headers.set('portal-id', portalId.toString())
      : new HttpHeaders({ 'portal-id': portalId.toString() });
  }

  // Note: replace with getHeadersWithPortalId(...)
  protected getHeadersWithPortal(portal: Portal, headers?: HttpHeaders) {
    return this.getHeadersWithPortalId(portal.id, headers);
  }
}
