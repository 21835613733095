import { Role } from '../model/role';

export const CRO = 'ROLE_CRO';
export const PHYSICIAN = 'ROLE_PHYSICIAN';
export const DELEGATE = 'ROLE_DELEGATE';
export const BULK_ORDER = 'ROLE_BULK_ORDER';
export const WHATSAPP = 'ROLE_WHATSAPP';
export const CRO_LIGHT = 'ROLE_CRO_LIGHT';
export const CTRY_MANAGER = 'ROLE_CTRY_MANAGER';

export const ROLE_CRO = [new Role(CRO)];
export const ROLE_PHYSICIAN = [new Role(PHYSICIAN)];
export const ROLE_DELEGATE = [new Role(DELEGATE)];
