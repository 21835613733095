import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import {
  APP_CONFIG,
  ArchimedCommonModule,
  AuthInterceptor,
  AuthState,
  DefaultMissingTranslationHandler,
  TranslationLoader,
} from '@archimed-frontend/archimed-common';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';
import { MatomoInitializationMode, NgxMatomoModule } from 'ngx-matomo-client';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ARCHIMED_CONFIG } from './app.settings';
import { LoggedoutModule } from './logged-out/loggedout.module';
import { PhysicianRouteReuseStrategy } from './physician-route-reuse-strategy';
import { AppStateService } from './shared/app-state.service';
import { ArchimedSharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslationLoader(http);
}
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // Note: use HttpLoader provided with ngx-translate?
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultMissingTranslationHandler,
      },
    }),
    NgxMatomoModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED,
    }),
    ArchimedSharedModule,
    AppRoutingModule,
    ArchimedCommonModule.forRoot(ARCHIMED_CONFIG),
    LoggedoutModule,
    RippleModule,
    MessagesModule,
    ToastModule,
  ],
  declarations: [AppComponent],
  providers: [
    RxState,
    AppStateService,
    MessageService,
    { provide: APP_CONFIG, useValue: ARCHIMED_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: PhysicianRouteReuseStrategy,
    },
    {
      provide: AuthState,
      useExisting: AppStateService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
