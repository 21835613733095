import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Country } from '../../model/country';
import { BaseService } from '../base.service';
import { Region } from '../../model/region';
import { Role } from '../../model/role';

@Injectable()
export class RegionRestService extends BaseService {
  private regionUrl = this.endpointUrl + '/v1/region';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getRegions(): Observable<Region[]> {
    return this.http
      .get<Region[]>(`${this.regionUrl}/list`)
      .pipe(catchError(this.handleError<Region[]>('getRegions')));
  }

  createRegion(region: Region): Observable<Region> {
    return this.http
      .post<Region>(`${this.regionUrl}/create`, region)
      .pipe(catchError(this.handleError<Region>('createRegion')));
  }

  updateRegion(region: Region): Observable<Region> {
    return this.http
      .put<Region>(`${this.regionUrl}/${region.id}`, region)
      .pipe(catchError(this.handleError<Region>('updateRegion')));
  }

  updateRegionRoles(regionId: number, roles: Role[]): Observable<Region> {
    return this.http
      .put<Region>(`${this.regionUrl}/roles/${regionId}`, roles)
      .pipe(catchError(this.handleError<Region>('updateRegionRoles')));
  }

  updateRegionCountries(
    regionId: number,
    countries: Country[]
  ): Observable<Region> {
    return this.http
      .put<Region>(`${this.regionUrl}/countries/${regionId}`, countries)
      .pipe(catchError(this.handleError<Region>('updateRegionCountries')));
  }
}
