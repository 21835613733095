import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AppSettings,
  APP_CONFIG,
  Portal,
  AnalysisPool,
  PagedResult,
} from '../../../index';
import { BaseService } from '../base.service';
import { LazyLoadEvent } from 'primeng/api';

@Injectable()
export class PoolService extends BaseService {
  private apiUrl = this.endpointUrl + '/v1/pool';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getAllBillable(portal: Portal): Observable<AnalysisPool[]> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.get<AnalysisPool[]>(`${this.apiUrl}/all/billable`, {
      headers,
    });
  }

  getPaged(
    lazyLoadEvent: LazyLoadEvent,
    portal?: Portal
  ): Observable<PagedResult<AnalysisPool>> {
    const headers = portal != null ? this.getHeadersWithPortal(portal) : new HttpHeaders();
    const params = this.getPaginationHttpParams(lazyLoadEvent);
    return this.http.post<PagedResult<AnalysisPool>>(
      `${this.apiUrl}/paged`,
      lazyLoadEvent.filters,
      { params, headers }
    );
  }
}
