import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Physician } from '../../model/physician';
import { PagedResult } from '../../util/pagedresult';
import { Cro } from '../../model/cro';
import { LazyLoadEvent } from 'primeng/api';

@Injectable()
export class CroService extends BaseService {
  private url = this.endpointUrl + '/v1/cro';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  /**
   * Get a paged list of all CROs filtered by the lazyLoadEvent filters.
   *
   * @param portalId Only CROs connected to this portal.
   * @param lazyLoadEvent Filters and pagination.
   * @param physicianId Optional. If set, CROs which are connected to this
   * physician are removed from the result.
   */
  list(
    portalId: number,
    lazyLoadEvent: LazyLoadEvent,
    physicianId?: number
  ): Observable<PagedResult<Physician>> {
    let httpParams = this.getPaginationHttpParams(lazyLoadEvent);
    httpParams = httpParams.set('portal-id', portalId);
    httpParams =
      physicianId != null
        ? httpParams.set('physician-id', physicianId)
        : httpParams;
    return this.http.post<PagedResult<Physician>>(
      `${this.url}/list`,
      lazyLoadEvent.filters,
      { params: httpParams }
    );
  }

  listRelatedToPhysician(
    portalId: number,
    relatedPhysicianId: number
  ): Observable<Cro[]> {
    const queryParams = {
      params: {
        'portal-id': portalId,
      },
    };
    return this.http.get<Cro[]>(
      `${this.url}/related-to-physician/${relatedPhysicianId}`,
      queryParams
    );
  }

  removeRelationToPhysician(
    physicianId: number,
    croId: number
  ): Observable<void> {
    const queryParams = {
      params: {
        'cro-id': croId,
      },
    };
    return this.http.delete<void>(
      `${this.url}/related-to-physician/${physicianId}`,
      queryParams
    );
  }

  addRelationToPhysician(physicianId: number, croId: number): Observable<void> {
    return this.http.put<void>(
      `${this.url}/related-to-physician/${physicianId}`,
      croId
    );
  }
}
