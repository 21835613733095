import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, FormControl } from '@angular/forms';

function validatePasswordFactory() {
  return (c: FormControl) => {
    return /[a-z]+/.test(c.value) &&
      /[A-Z]+/.test(c.value) &&
      (/[!@#%^()?*]+/.test(c.value) || /[0-9]+/.test(c.value))
      ? null
      : {
          validatePassword: {
            valid: false,
          },
        };
  };
}

@Directive({
  selector: '[validatePassword][ngModel],[validatePassword][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordValidatorDirective),
      multi: true,
    },
  ],
})
export class PasswordValidatorDirective {
  // eslint-disable-next-line @typescript-eslint/ban-types
  validator: Function;

  constructor() {
    this.validator = validatePasswordFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
