import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class DefaultMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams): Observable<string> {
    const translateService = params.translateService;
    const key = params.key;
    const separaterIndex = key.indexOf('|');
    if (separaterIndex > 0) {
      const defaultKey = key.replace(/[^.]+\|/, '');
      return translateService.get(defaultKey, params.interpolateParams);
    } else {
      // Note: deactivated due to too many warnings
      // console.warn(`missing translation for key "${key}"`);
      return of(key);
    }
  }
}
