import { Inject, Injectable } from '@angular/core';
import {
  AnalysisPool,
  APP_CONFIG,
  AppSettings,
  KitOrderDbsId,
  KitOrderDestination,
  KitOrderRequest,
  PagedRequest,
  PagedResult,
  Physician,
  Portal,
  RestUtils,
} from '../../../index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KitOrder } from '../../model/kitOrder';
import { BaseService } from '../base.service';

@Injectable({ providedIn: 'root' })
export class KitOrderService extends BaseService {
  private url = `${this.endpointUrl}/v1/kit-order`;

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getKitOrder(kitOrderId: number): Observable<KitOrder> {
    return this.http.get<KitOrder>(`${this.url}/${kitOrderId}`);
  }

  getKitOrders(pagedRequest?: PagedRequest): Observable<PagedResult<KitOrder>> {
    const params: HttpParams = RestUtils.pagedRequestToHttpParams(pagedRequest);
    return this.http.post<PagedResult<KitOrder>>(
      `${this.url}/list`,
      pagedRequest.filters,
      { params }
    );
  }

  getPoolsWithKits(
    portal: Portal,
    physician?: Physician,
    countryCode?: string
  ): Observable<AnalysisPool[]> {
    const headers = this.getHeadersWithPortal(portal);
    let params = new HttpParams();
    params =
      physician != null ? params.set('physician-id', physician.id) : params;
    params = countryCode != null ? params.set('country', countryCode) : params;
    return this.http.get<AnalysisPool[]>(`${this.url}/pool`, {
      headers,
      params,
    });
  }

  getDbsIds(
    kitOrderId: number,
    pagedRequest?: PagedRequest
  ): Observable<PagedResult<KitOrderDbsId>> {
    const params: HttpParams = RestUtils.pagedRequestToHttpParams(pagedRequest);
    return this.http.post<PagedResult<KitOrderDbsId>>(
      `${this.url}/${kitOrderId}/dbs-ids/list`,
      pagedRequest.filters,
      { params }
    );
  }

  addDbsId(kitOrderId: number, dbsId: string): Observable<null> {
    const body = { dbsId };
    return this.addDbsIds(kitOrderId, body);
  }

  addDbsIdRange(
    kitOrderId: number,
    dbsIdFrom: string,
    dbsIdTo: string
  ): Observable<null> {
    const body = { dbsIdFrom, dbsIdTo };
    return this.addDbsIds(kitOrderId, body);
  }

  private addDbsIds(
    kitOrderId: number,
    body: { dbsId: string } | { dbsIdFrom: string; dbsIdTo: string }
  ): Observable<null> {
    return this.http.post<null>(`${this.url}/${kitOrderId}/dbs-ids`, body);
  }

  removeDbsId(kitOrderId: number, dbsId: string): Observable<null> {
    return this.http.delete<null>(`${this.url}/${kitOrderId}/dbs-ids/${dbsId}`);
  }

  setTracking(kitOrderId: number, tracking: string): Observable<null> {
    return this.http.put<null>(`${this.url}/${kitOrderId}/tracking`, tracking);
  }

  setQuantity(kitOrderId: number, quantity: number): Observable<null> {
    console.log(quantity, kitOrderId);
    return this.http.put<null>(`${this.url}/${kitOrderId}/quantity`, quantity);
  }

  setLotNumber(kitOrderId: number, lotNumber: string): Observable<null> {
    return this.http.put<null>(
      `${this.url}/${kitOrderId}/lot-number`,
      lotNumber
    );
  }

  setShipped(kitOrderId: number, shipped: boolean): Observable<null> {
    return this.http.put<null>(`${this.url}/${kitOrderId}/shipped`, shipped);
  }

  setCourier(kitOrderId: number, courier: string): Observable<null> {
    return this.http.put<null>(`${this.url}/${kitOrderId}/courier`, courier);
  }

  setProcessing(kitOrderId: number, processing: boolean): Observable<null> {
    return this.http.put<null>(
      `${this.url}/${kitOrderId}/processing`,
      processing
    );
  }

  setInternalComment(
    kitOrderId: number,
    internalComment: string
  ): Observable<null> {
    return this.http.put<null>(
      `${this.url}/${kitOrderId}/internal-comment`,
      internalComment
    );
  }

  getKitOrderDestinations(
    pagedRequest?: PagedRequest
  ): Observable<PagedResult<KitOrderDestination>> {
    const params: HttpParams = RestUtils.pagedRequestToHttpParams(pagedRequest);
    return this.http.post<PagedResult<KitOrderDestination>>(
      `${this.url}/destination/search`,
      pagedRequest.filters,
      { params }
    );
  }

  submitKitOrder(order: KitOrderRequest): Observable<null> {
    return this.http.post<null>(this.url + '/', order);
  }
}
