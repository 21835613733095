import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { Portal } from '../../model/portal';
import { BaseService } from '../base.service';
import { Specialization } from '../../model/specialization';
import { ResourcePage } from '../../model/resourcePage';

@Injectable({
  providedIn: 'root',
})
export class PortalService extends BaseService {
  private publicApiUrl = this.endpointUrl + '/public';
  private portalApiUrl = this.endpointUrl + '/v1/portal';
  currentTitleKey: string;

  private currentPortal: BehaviorSubject<Portal> = new BehaviorSubject<Portal>(
    new Portal()
  );
  public portalAnnounced: Observable<Portal> =
    this.currentPortal.asObservable();

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient,
    private titleService: Title,
    private translateService: TranslateService
  ) {
    // private bsLocalService: BsLocaleService) {

    super(config);
    this.translateService.onLangChange.subscribe(($event: LangChangeEvent) => {
      // this.bsLocalService.use($event.lang);
      this.refreshTitle();
    });
  }

  static hasRole(portal: Portal, roleName: string): boolean {
    if (!portal || !portal.authorities) {
      return false;
    } else {
      return (
        portal.authorities.findIndex((role) => role.authority == roleName) != -1
      );
    }
  }

  static hasRoles(portal: Portal, roleNames: string[]): boolean {
    if (!portal?.authorities) {
      return false;
    }
    if (roleNames == null) {
      return true;
    }
    let contains = false;
    for (const token of roleNames) {
      if (
        portal.authorities.findIndex((role) => role.authority == token) != -1
      ) {
        contains = true;
        break;
      }
    }
    return contains;
  }

  /**
   * If Portal has been retrieved once by the API store it in the local storage
   * Then the portal will be returned from the local storage instead of the network.
   */

  loadPortal(): void {
    const portal: Portal = this.getPortalFromStorage();

    if (portal != null) {
      this.currentPortal.next(portal);
    } else {
      this.http
        .get<Portal>(this.publicApiUrl + '/info')
        .pipe(catchError(this.handleError<Portal>('getPortal', null)))
        .subscribe((p: Portal) => {
          // console.log('got portal', p);
          if (p != null) p.lastLoad = Date.now();
          sessionStorage.setItem('portal', JSON.stringify(p));
          this.currentPortal.next(p);
        });
    }
  }

  getPortals(searchString: string): Observable<Portal[]> {
    return this.http
      .get<Portal[]>(
        this.portalApiUrl + (searchString ? '/fuzzy/' + searchString : '/all')
      )
      .pipe(catchError(this.handleError<Portal[]>('getPortals', [])));
  }

  getResourcePageForPortal(
    type?: string,
    refEntityId?: number,
    portalId?: number,
    lang?: string
  ): Observable<ResourcePage> {
    let params: HttpParams = new HttpParams();
    params = portalId != null ? params.set('portalid', portalId) : params;
    params = type != null ? params.set('type', type) : params;
    params =
      refEntityId != null ? params.set('refentity', refEntityId) : params;
    params = lang != null ? params.set('lang', lang) : params;

    return this.http.get<ResourcePage>(
      this.portalApiUrl + `/resourcepage?${params.toString()}`
    );
  }

  createResourcePage(
    type: string,
    refEntityId: number,
    portalId: number,
    lang: string
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (portalId != null) {
      params = params.set('portalid', `${portalId}`);
    }
    if (type != null) {
      params = params.set('type', `${type}`);
    }
    if (lang != null) {
      params = params.set('lang', `${lang}`);
    }
    if (refEntityId != null) {
      params = params.set('refentity', `${refEntityId}`);
    }

    return this.http
      .post<any>(this.portalApiUrl + `/resourcepage?${params.toString()}`, {})
      .pipe(catchError(this.handleError<any>('createResourcePage')));
  }

  updateResourcePage(pageId: number, page: any): Observable<number> {
    return this.http
      .put<any>(this.portalApiUrl + `/resourcepage/${pageId}`, page)
      .pipe(catchError(this.handleError<any>('updateResourcePage')));
  }

  getAllSpecializations(langKey: string): Observable<Specialization[]> {
    let params = new HttpParams();
    params = langKey != null ? params.set('lang-key', langKey) : params;
    return this.http.get<Specialization[]>(
      `${this.publicApiUrl}/specializations`,
      { params }
    );
  }

  setTitle(title: string) {
    this.currentTitleKey = title;
    this.refreshTitle();
  }

  // to be used after language change
  refreshTitle(): void {
    if (!this.currentTitleKey || this.currentTitleKey === '') {
      return;
    }
    this.translateService.get(this.currentTitleKey).subscribe((m) => {
      this.portalAnnounced // Note: portal subscribe sollte nicht notwendig sein hier im service!
        .subscribe((portal) =>
          this.titleService.setTitle(portal.name + ' | ' + m)
        );
    });
  }

  getPortalFromStorage(): Portal {
    // cache portal for a day
    const p: Portal = JSON.parse(sessionStorage.getItem('portal'));
    if (p?.lastLoad == null) return null;
    if (Date.now() - p.lastLoad > 86400000) {
      return null;
    } else return p;
  }
}
