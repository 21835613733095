export class PagedResult<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  first: boolean;
  last: boolean;

  constructor() {
    this.content = new Array<T>();
    this.size = 0;
    this.number = 0;
    this.totalElements = 0;
    this.totalPages = 0;
    this.first = true;
    this.last = true;
  }
}
