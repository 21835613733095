<ng-container *ngIf="state$ | async as state">
  <header-loggedout [currentPortal]="state.portal"></header-loggedout>
  <div [hidden]="state.loading" class="container">
    <h2 translate="">onetimeaccess.accesslink-is-invalid</h2>
    <div class="row">
      <div class="col-md-5">
        <br />
        <p translate="">onetimeaccess.your-accesslink-is-invalid-has-b</p>
        <p translate="">onetimeaccess.if-you-forgot-your-password-plea</p>
        <a [routerLink]="['/forgotpassword']" class="btn" translate=""
          >onetimeaccess.forgot-password</a
        >
      </div>
      <div class="col-md-5">
        <supportbox
          class="pull-right"
          [currentPortal]="state.portal"
        ></supportbox>
      </div>
    </div>
  </div>
</ng-container>
