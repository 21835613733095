<ng-container *ngIf="state$ | async as state">
  <header-loggedout [currentPortal]="state.portal"></header-loggedout>
  <div class="container">
    <div *ngIf="state.loading" class="loading-points-fullscreen">&nbsp;</div>

    <div *ngIf="!state.loading">
      <div *ngIf="!state.userHasPasswd">
        <reset-password-component
          [forwardToLogin]="false"
          (registrationComplete)="updatePassord$.next($event)"
          [token]="token"
          [wasMetaRegistration]="true"
        ></reset-password-component>
      </div>
      <div *ngIf="state.userHasPasswd">
        <div *ngIf="state.showSuccess">
          <h2 translate="">activation.the-activation-of-your-account-w</h2>
          <br /><br />
          <div class="row">
            <div class="col-md-5">
              <br />
              <p translate="">activation.you-can-login-into-your-accoun</p>
              <a
                [routerLink]="['/login/' + state.currentLanguage]"
                class="btn"
                translate
                id="log-in"
                >activation.login</a
              >
            </div>
            <div class="col-md-5">
              <supportbox
                [currentPortal]="state.portal"
                class="pull-right"
              ></supportbox>
            </div>
          </div>
        </div>

        <div *ngIf="!state.showSuccess">
          <h2 translate="">activation.this-activation-link-is-not-vali</h2>
          <br /><br />
          <div class="row">
            <div class="col-md-5">
              <br />
              <p translate="">activation.please-login-to-your-account-o</p>
              <a
                [routerLink]="['/login/' + state.currentLanguage]"
                class="btn"
                translate=""
                >activation.login</a
              >
            </div>
            <div class="col-md-5">
              <supportbox
                [currentPortal]="state.portal"
                class="pull-right"
              ></supportbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
