import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import {
  AuthState,
  Country,
  Physician,
  Portal,
  PortalService,
  PortalUser,
  PublicRestService,
  ROLE_CRO,
  ROLE_DELEGATE,
  ROLE_PHYSICIAN,
  SupportedLanguage,
  TranslationLoadService,
  UserService,
} from '@archimed-frontend/archimed-common';
import { TranslateService } from '@ngx-translate/core';
import { RxState } from '@rx-angular/state';
import { MatomoInitializerService, MatomoTracker } from 'ngx-matomo-client';
import { combineLatest, Observable, of, Subject, zip } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface AppState {
  portal: Portal;
  supportedLanguages: SupportedLanguage[];
  currentLanguage: string;
  countries: Country[];
  currentUser: PortalUser;
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService extends RxState<AppState> implements AuthState {
  fetchCountries$ = new Subject<void>();
  fetchCurrentUser$ = new Subject<void>();

  refreshCurrentUser$ = this.fetchCurrentUser$.pipe(
    switchMap(() =>
      this.userService.loadPortalUser().pipe(
        switchMap((portalUser: Physician) => {
          if (portalUser.metaRoleActive) {
            portalUser.authorities = [...ROLE_CRO, ...portalUser.authorities];
          } else {
            if (UserService.isPhysicianDelegate(portalUser)) {
              portalUser.authorities = [
                ...ROLE_DELEGATE,
                ...portalUser.authorities,
              ];
            } else {
              portalUser.authorities = [
                ...ROLE_PHYSICIAN,
                ...portalUser.authorities,
              ];
            }
          }
          return zip(
            of(portalUser),
            this.translationLoadService.setLanguage(portalUser.language)
          );
        }),
        map(([user, lang]) => {
          return {
            currentUser: user,
            currentLanguage: user.language,
          };
        })
      )
    )
  );

  refreshCountries$ = combineLatest([
    this.select('currentLanguage'),
    this.fetchCountries$,
  ]).pipe(
    switchMap(([currentLang]) => {
      const currentUser = this.get('currentUser');
      return this.publicRestService.getCountries(currentLang).pipe(
        map((countries) => {
          if (
            currentUser &&
            !countries.find((c) => c.code == currentUser.country.code)
          ) {
            countries.unshift(currentUser.country);
            countries.sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
          }
          return countries;
        })
      );
    })
  );

  initMatomo$ = this.select('portal').pipe(
    filter((portal) => portal != null),
    tap((portal) => {
      console.log('init matomo');
      this.matomoInitializer.initializeTracker({
        // scriptUrl: environment.matomoScriptUrl,
        trackerUrl: '/piwik/',
        siteId: portal.siteId,
      });
      this.matomoTracker.trackEvent('Visit', 'Load', environment.version);
    })
  );

  routerSnapshot$ = this.router.events.pipe(
    filter((event): boolean => event instanceof NavigationEnd),
    map(() => this.router.routerState.snapshot.root)
  );

  // Collect all parameters from the current URL
  routerParams$ = this.routerSnapshot$.pipe(
    map((snapshot) => this.collectParams(snapshot))
  );

  // Retrieve the language parameter 'lang' from the current URL if exists
  urlLang$ = this.routerParams$.pipe(
    filter((params) => params['lang'] != null),
    map((params) => params['lang'])
  );

  // update the window title on navigation or language change
  // https://toddmotto.com/dynamic-page-titles-angular-2-router-events
  titleUpdates$ = combineLatest([
    this.select('currentLanguage'),
    this.routerSnapshot$,
  ]).pipe(
    map(([lang, route]) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return { lang, route };
    }),
    filter((routeInfo) => routeInfo.route.outlet === 'primary'),
    mergeMap((routeInfo) =>
      of({ lang: routeInfo.lang, title: routeInfo.route.data['title'] })
    ),
    concatMap((routeInfo) => this.translateService.get(routeInfo.title)),
    tap((translatedTitle) => {
      // console.log('title', routeInfo);
      this.titleService.setTitle(translatedTitle);
    })
  );

  updateLanguage$ = combineLatest([
    this.urlLang$,
    this.select('supportedLanguages'),
  ]).pipe(
    // tap(([l, s]) => console.log('urlLang', l)),
    map(([language, supported]) => {
      if (language !== 'en') {
        // english is always supported. this check avoids errors in Backoffice App
        if (supported.findIndex((lang) => lang.langKey === language) === -1) {
          // language not supported
          language = 'en'; // default to en
        }
      }
      return language;
    })
  );

  constructor(
    // private matomoInjector: MatomoInjector,
    private matomoInitializer: MatomoInitializerService,
    private matomoTracker: MatomoTracker,
    private portalService: PortalService,
    private translationLoadService: TranslationLoadService,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService,
    private titleService: Title,
    private publicRestService: PublicRestService
  ) {
    super();
    this.connect('portal', this.portalService.portalAnnounced);
    this.connect(
      this.translationLoadService.loadSupportedLanguagesAsync().pipe(
        map((languages) => {
          let lang = 'en';
          if (translationLoadService.getCurrentSessionLanguage() != null) {
            lang = translationLoadService.getCurrentSessionLanguage();
          } else {
            lang = translationLoadService.getBrowserLanguage();
          }
          return {
            supportedLanguages: languages,
            currentLanguage: lang,
            countries: [],
          };
        })
      )
    );
    this.connect('currentLanguage', this.updateLanguage$);
    // this.hold(
    //   this.select('currentLanguage').pipe(
    //     concatMap((languange) =>
    //       this.translationLoadService.setLanguage(languange)
    //     )
    //   )
    // );
    this.hold(this.initMatomo$);
    this.hold(this.routerParams$);
    this.hold(this.titleUpdates$);
    this.connect(this.refreshCurrentUser$);
    this.connect('countries', this.refreshCountries$);
  }

  getCurrentUser(): PortalUser {
    return this.get('currentUser');
  }

  selectCurrentUser(): Observable<PortalUser> {
    return this.select('currentUser');
  }

  fetchCurrentUser(): void {
    this.fetchCurrentUser$.next();
  }

  private collectParams(root: ActivatedRouteSnapshot): any {
    const params: any = {};
    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
      Object.assign(params, snapshot.params);
      snapshot.children.forEach(mergeParamsFromSnapshot);
    })(root);
    return params;
  }

  public fetchCountries(): void {
    this.fetchCountries$.next();
  }

  public doFetchCountries(): Observable<Country[]> {
    return this.select('countries').pipe(
      take(1),
      filter((countries) => countries.length < 1),
      // tap((_) => console.log('fetch ctry')),
      tap(() => this.fetchCountries())
    );
  }
}
