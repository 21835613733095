import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Analysis } from '../../model/analysis';
import { AnalysisReport } from '../../model/analysisreport';
import { APP_CONFIG, AppSettings } from '../../model/appsettings';
import { PagedResult } from '../../util/pagedresult';
import { BaseService } from '../base.service';

@Injectable()
export class AnalysisService extends BaseService {
  private portalAnalysis = this.endpointUrl + '/v1/analysis';

  private portalDownload = this.endpointUrl + '/v1/analysis/download/';

  private portalDeleteFile = this.endpointUrl + '/v1/analysis/deletefile/';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  createAnalysis(analysis: Analysis): Observable<Analysis> {
    return this.http
      .post<Analysis>(this.portalAnalysis, analysis)
      .pipe(catchError(this.handleError<Analysis>('createAnalysis')));
  }

  downloadUrl(analysisReport: AnalysisReport): string {
    return this.portalDownload + analysisReport.fileNameHash;
  }

  downloadFile(analysisReport: AnalysisReport): Observable<Blob> {
    return this.http
      .get(this.downloadUrl(analysisReport), { responseType: 'blob' })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError(this.handleError<Blob>('downloadFile'))
      );
  }

  downloadAll(analysis: Analysis): Observable<Blob> {
    return this.http
      .get(`${this.portalDownload}analysis/${analysis.id}`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError(this.handleError<Blob>('downloadAll'))
      );
  }

  downloadAllForCro(): Observable<Blob> {
    return this.http
      .get(`${this.portalDownload}cro`, {
        responseType: 'blob',
      })
      .pipe(
        map((res: any) => {
          const blob: Blob = new Blob([res], {
            type: 'application/octet-stream',
          });
          return blob;
        }),
        catchError(this.handleError<Blob>('downloadAllForCro'))
      );
  }

  deleteFile(analysisReport: AnalysisReport): Observable<boolean> {
    return this.http
      .delete<boolean>(this.deleteUrl(analysisReport))
      .pipe(catchError(this.handleError<boolean>('deleteFile', false)));
  }

  // Note: parameter handling looks weird: if no dbsId hten call URL with no servermethod?
  // Note: seems to not be used anymore
  loadAnalysis(dbsId: string): Observable<Analysis> {
    return this.http
      .get<Analysis>(this.portalAnalysis + dbsId ? '/' + dbsId : '')
      .pipe(catchError(this.handleError<Analysis>('loadAnalysis')));
  }

  getAnalysis(id: number): Observable<Analysis> {
    return this.http
      .get<Analysis>(`${this.portalAnalysis}/single/${id}`)
      .pipe(catchError(this.handleError<Analysis>('getAnalysis')));
  }

  loadAnalyses(
    filterOptions?: any,
    pageOffset?: number,
    rowsPerPage?: number
  ): Observable<PagedResult<Analysis>> {
    const params: HttpParams = new HttpParams()
      .set('page', pageOffset.toFixed(0))
      .set('size', rowsPerPage.toFixed(0));

    return this.http
      .post<PagedResult<Analysis>>(
        this.portalAnalysis + '/lab/fuzzy',
        filterOptions,
        { params: params }
      )
      .pipe(
        catchError(
          this.handleError<PagedResult<Analysis>>(
            'loadAnalyses',
            new PagedResult()
          )
        )
      );
  }

  getUploadUrl(analysis: Analysis, status: string): string {
    return `${this.portalAnalysis}/upload/${analysis.id}/${status}`;
  }

  postStatusChange(analysis: Analysis): Observable<boolean> {
    return this.http
      .put<boolean>(
        this.portalAnalysis + '/status/' + analysis.id + '/' + analysis.status,
        null
      )
      .pipe(catchError(this.handleError<boolean>('postStatusChange')));
  }

  postStatusInfoChange(analysis: Analysis): Observable<boolean> {
    return this.http
      .put<boolean>(
        this.portalAnalysis + '/statusinfo/' + analysis.id + '/',
        analysis.statusInfo
      )
      .pipe(catchError(this.handleError<boolean>('postStatusInfoChange')));
  }

  postSendMail(analysis: Analysis): Observable<boolean> {
    return this.http
      .post<boolean>(this.portalAnalysis + '/sendmail/' + analysis.id, null)
      .pipe(catchError(this.handleError<boolean>('postSendMail')));
  }

  postSampleTakenOn(analysis: Analysis): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${this.portalAnalysis}/sample-taken-on/${analysis.id}`,
        analysis.sampleTakenOn
      )
      .pipe(catchError(this.handleError<boolean>('postSampleTakenOn')));
  }

  postDbsId(analysis: Analysis): Observable<boolean> {
    return this.http
      .put<boolean>(
        `${this.portalAnalysis}/dbs-id/${analysis.id}`,
        analysis.dbsId
      )
      .pipe(catchError(this.handleError<boolean>('postDbsId')));
  }

  exportToDlx(analysis: Analysis): Observable<Analysis> {
    return this.http
      .put<Analysis>(
        `${this.portalAnalysis}/export-to-dlx/${analysis.id}`,
        null
      )
      .pipe(catchError(this.handleError<Analysis>('exportToDlx')));
  }

  private deleteUrl(analysisReport: AnalysisReport) {
    return this.portalDeleteFile + analysisReport.fileNameHash;
  }
}
