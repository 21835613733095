import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AppSettings,
  APP_CONFIG,
  PagedRequest,
  PagedResult,
  BreakingNewsItem,
  BannerNewsItem,
  NewsItem,
  RestUtils,
  Portal,
} from '../../../index';
import { BaseService } from '../base.service';

@Injectable()
export class NewsService extends BaseService {
  private apiUrl = this.endpointUrl + '/v1/news';

  constructor(
    @Inject(APP_CONFIG) config: AppSettings,
    private http: HttpClient
  ) {
    super(config);
  }

  getBreakingNews(): Observable<BreakingNewsItem[]> {
    return this.http.get<BreakingNewsItem[]>(`${this.apiUrl}/breaking`);
  }

  getBannerNews(): Observable<BannerNewsItem> {
    return this.http.get<BannerNewsItem>(`${this.apiUrl}/banner`);
  }

  getNewsItemList(
    newsItemType: string,
    pagedRequest: PagedRequest,
    portal: Portal
  ): Observable<PagedResult<NewsItem>> {
    let params = RestUtils.pagedRequestToHttpParams(pagedRequest);
    params = params.set('type', newsItemType);
    const headers = this.getHeadersWithPortal(portal);
    return this.http.post<PagedResult<NewsItem>>(
      `${this.apiUrl}/management/list`,
      pagedRequest.filters,
      { params, headers }
    );
  }

  acknowledgeBannerNews(newsItem: BannerNewsItem): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${newsItem.id}/acknowledge-banner`,
      null
    );
  }

  acknowledgeBreakingNews(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/acknowledge-breaking`, null);
  }

  getNewsItem(newsItemId: number, portal: Portal): Observable<NewsItem> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.get<NewsItem>(`${this.apiUrl}/management/${newsItemId}`, {
      headers,
    });
  }

  saveNewsItem(newsItem: NewsItem, portal: Portal): Observable<NewsItem> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.put<NewsItem>(`${this.apiUrl}/management`, newsItem, {
      headers,
    });
  }

  deleteNewsItem(newsItem: NewsItem, portal: Portal): Observable<void> {
    const headers = this.getHeadersWithPortal(portal);
    return this.http.delete<void>(`${this.apiUrl}/management/${newsItem.id}`, {
      headers,
    });
  }
}
