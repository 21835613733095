import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';

export class TranslationLoader extends TranslateLoader {
  translationBaseUrl = 'api/public/i18/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    return this.httpClient.get(this.translationBaseUrl + lang);
  }
}
