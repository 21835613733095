import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Portal, UserService } from '@archimed-frontend/archimed-common';
import { MatomoTracker } from 'ngx-matomo-client';
import { AppStateService } from '../../shared/app-state.service';
import { RxState } from '@rx-angular/state';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface ActivationState {
  portal: Portal;
  token: string | null;
  userHasPasswd: boolean;
  showSuccess: boolean;
  showPasswordEntry: boolean;
  loading: boolean;
  currentLanguage: string;
}
@Component({
  templateUrl: 'activation.component.html',
  styleUrls: ['activation.component.css'],
  providers: [RxState],
})
export class ActivationComponent implements OnInit {
  state$ = this.state.select();

  /**
   * Retrieves the token parameter from the URL
   */
  urlToken$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  /**
   * Once we have a token, check if the user has a password.
   * If true, we can continue to try to activate, therefore
   * loading stays true as well. If fasle a set password template
   * will be shown.
   */
  checkUserHasPassword$ = this.state.select('token').pipe(
    switchMap((token) =>
      this.userService.userHasPassword(token).pipe(
        map((hasPasswd) => {
          return {
            userHasPasswd: hasPasswd,
            loading: hasPasswd,
          };
        })
      )
    )
  );

  /**
   * Try to activate the user account if the user already has a password set.
   * If activation is successful, we show a success message template and send
   * information to matomo. If not a failure message template is shown.
   */
  activateUser$ = this.state.select('userHasPasswd').pipe(
    filter((hasPasswd) => hasPasswd === true),
    withLatestFrom(this.state.select('token')),
    switchMap(([hasPwd, token]) => {
      return this.userService.activateUser(token).pipe(
        map((ret) => {
          let success = false;
          if (ret != 'false') {
            this.matomoTracker.trackEvent('User', 'Activated', 'Physician');
            success = true;
          }
          return {
            showSuccess: success,
            loading: false,
          };
        })
      );
    })
  );

  updatePassord$ = new Subject<boolean>();

  constructor(
    private appState: AppStateService,
    private state: RxState<ActivationState>,
    private route: ActivatedRoute,
    private userService: UserService,
    private matomoTracker: MatomoTracker,
    private titleService: Title
  ) {
    /**Retrieve the current portal from the app state and then initialize some state variables*/
    this.state.connect(
      this.appState.select('portal').pipe(
        map((portal) => ({
          portal,
          showSuccess: false,
          loading: true,
        }))
      )
    );
    this.state.connect(
      'currentLanguage',
      this.appState.select('currentLanguage')
    );
    this.state.connect('token', this.urlToken$);
    this.state.connect(this.checkUserHasPassword$);
    this.state.connect('userHasPasswd', this.updatePassord$);
    this.state.connect(this.activateUser$);
  }

  ngOnInit() {
    this.matomoTracker.setDocumentTitle(this.titleService.getTitle());
    this.matomoTracker.trackPageView();
  }
}
